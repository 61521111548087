import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useIsOpen from '../../../logics/utils/isOpen';
import FirstVisitDialog from './dialog';

export default function FirstVisitDialogIfNotDetective(props) {
  const [isOpen, handleOpen, handleClose] = useIsOpen(false)
  const isDetective = useSelector(state => state.account.isDetective)
  const fUser = useSelector(state => state.account.firebaseUser)

  useEffect(() => {
    if (fUser && isDetective === false) {
      handleOpen()
    }
  }, [isDetective, fUser, handleOpen])

  return (
    <FirstVisitDialog isOpen={isOpen} handleClose={handleClose} handleOpen={handleOpen} />
  );
}