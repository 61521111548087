import { Avatar, Box, Button, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { FullChipList, SimpleList } from '../../../components/molecules/categoryList';
import useTransMaster from '../../../logics/services/master/trans';

const useStyles = makeStyles((theme) => ({
    text: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: "100%"
    },
    textFieldStyle: {
        width: "100%",
        marginBottom: theme.spacing(1)
    },
    cover: {
        position: "relative",
        width: "30%",
        "&::before": {
            content: '""',
            display: "block",
            paddingTop: '100%',
        }
    },
    avatar: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    verifyContent: {
        maxHeight: "55vh",
        overflowX: "scroll",
        paddingBottom: theme.spacing(3)
    }

}));

function Verify(props) {
    const classes = useStyles()
    const { onClickBack, onClickNext, basic, detail, photo, registError } = props
    const {
        transIdToNameFromMaster,
        transIdToNameFromAccountMaster,
        transIdListToNameListFromMaster,
    } = useTransMaster()
    return (
        <React.Fragment>
            <DialogContent dividers>
            { registError 
                ? <Box>
                    <Typography variant="h3" component="h2" className={classes.text}>
                        エラーが発生しました
                    </Typography>
                    <Typography variant="subtitle1" className={classes.text}>
                        {registError}
                    </Typography>
                </Box>
            : <Box className={classes.verifyContent}>
                <Typography variant="h3" component="h2" className={classes.text}>
                    登録内容の確認
                </Typography>
                <Typography variant="subtitle1" className={classes.text}>
                    登録内容にお間違えがないか確認ください。問題なければ下部の「登録する」を押してください.
                    
                </Typography>
                <Box display="flex" justifyContent="center" alignContent="center" alignItems="center">
                    <Box className={classes.cover}>
                        <Avatar src={photo[0] && photo[0].cropedImage} className={classes.avatar} />
                    </Box>
                </Box>
                <SimpleList
                    title="ユーザーID"
                    value={basic.publicId}
                />
                <SimpleList
                    title="表示名"
                    value={basic.displayName}
                />
                <SimpleList
                    title="メールアドレス"
                    value={basic.email}
                />
                <SimpleList
                    title="性別"
                    value={transIdToNameFromAccountMaster(detail.sex, "sex")}
                />
                <SimpleList
                    title="生年月日"
                    value={detail.birthYear && detail.birthMonth && detail.birthDay &&
                    `${detail.birthYear}年${detail.birthMonth}月${detail.birthDay}日`}
                />
                <SimpleList
                    title="都道府県"
                    value={transIdToNameFromMaster(detail.pref, "pref")}
                />
                <FullChipList
                    title="好きな古着ジャンル"
                    chipList={transIdListToNameListFromMaster(detail.fabThriftingGenre, "thriftingGenre")}
                />
                <FullChipList
                    title="好きなファッションジャンル"
                    chipList={transIdListToNameListFromMaster(detail.fabFashionGenre, "fashionGenre")}
                />
                <SimpleList
                    title="自己紹介"
                    value={detail.introduction}
                />
                <SimpleList
                    title="職業"
                    value={transIdToNameFromAccountMaster(detail.occupation, "occupation")}
                />
                <SimpleList
                    title="平均予算"
                    value={transIdToNameFromAccountMaster(detail.avgBudgetLine, "avgBudgetLine")}
                />
            </Box>
            }
            </DialogContent>
            <DialogActions>
            <Grid container justify="space-around" spacing={1} >
                <Grid item xs={6} >
                    <Button
                        variant="contained"
                        onClick={onClickBack}
                        fullWidth
                        color="secondary">
                        戻る
                    </Button>
                </Grid>
                {!registError &&
                    <Grid item xs={6}>
                        <Button

                            variant="contained"
                            fullWidth
                            onClick={onClickNext}
                            color="primary">
                            登録する
                        </Button>
                    </Grid>
                }
            </Grid>
        </DialogActions>
        </React.Fragment>
    )
}

export default Verify
