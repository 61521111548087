import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import { useSelector } from "react-redux";
import { LinkWithState } from "../../../logics/utils/useRouterWithState";
import MyAvatar from "./myAvatar";

export default function Avatar(props) {
  const fuser = useSelector((state) => state.account.firebaseUser);
  const accountLoading = useSelector((state) => state.account.isLoading);
  return (
    <React.Fragment>
      {accountLoading ? (
        <CircularProgress color="inherit" size={30} />
      ) : fuser ? (
        <MyAvatar />
      ) : (
        <Button
          component={LinkWithState}
          to="/signin"
          color="inherit"
          disableElevation
          size="small"
        >
          ログイン
        </Button>
      )}
    </React.Fragment>
  );
}
