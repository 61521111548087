import { useEffect } from "react";

const useScrollTop = (dependencyList) => {
    const dependency = Object.assign([], dependencyList)
    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, dependency);
};

export default useScrollTop;