import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginX: theme.spacing(1),
        // minWidth: 300,
        // maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            border: '1px solid #d3d4d5',
        },
    },
};

function getStyles(id, value, theme) {
    return {
        fontWeight:
            value !== id
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium
    };
}

function SelectWithChips(props) {
    const classes = useStyles()
    const { name, list, control, label, onBlur, className, required } = props
    const theme = useTheme();

    return (
        <Box className={className}>
            <FormControl className={classes.formControl} variant="outlined" fullWidth>
                <InputLabel id="filterName">{label}</InputLabel>
                <Controller
                    control={control}
                    name={name}
                    defaultValue=""
                    rules={{ required: required }}
                    render={({ value, onChange }) => (
                        <Select
                            labelId={`${name}Label`}
                            id={name}
                            required={required}
                            value={value || ""}
                            onChange={onChange}
                            onBlur={onBlur}
                            input={<OutlinedInput id="select-multiple-chip" style={{ minHeight: 40 }} size="small" />}
                            renderValue={(selected) => {
                                const item = list.find(item => item.id === selected)
                                return (
                                    < div className={classes.chips} >
                                        <Chip
                                            size="small"
                                            key={selected}
                                            label={item.name}
                                            className={classes.chip}
                                        />
                                    </div>
                                )
                            }}
                            MenuProps={MenuProps}
                        >
                            <MenuItem key="empty" value="">未選択</MenuItem>
                            {list.map((item) => (
                                <MenuItem key={item.id} value={item.id} style={getStyles(item.id, value, theme)}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>)
                    }
                />
            </FormControl >
        </Box>
    )
}

export default SelectWithChips
