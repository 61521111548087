import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import MultiSelectWithChips from "../../../components/molecules/multiSelectWithChips";
import SelectWithChips from "../../../components/molecules/selectWithChips";

const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  textFieldStyle: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  title: {
    marginTop: theme.spacing(1),
  },
  typography: {
    align: "center",
  },
  form: {
    maxWidth: "100%",
  },
}));

function DetailAccountInfo(props) {
  const classes = useStyles();
  const { onClickBack, onClickNext, onClickSkip, defaultValues } = props;
  const accountMaster = useSelector((state) => state.account.accountMaster);
  const shopMaster = useSelector((state) => state.master);
  const { control, handleSubmit, register, setValue, errors } = useForm({
    defaultValues: defaultValues,
  });

  return (
    <React.Fragment>
      <DialogContent dividers>
        <form
          onSubmit={handleSubmit(onClickNext)}
          className={classes.form}
          id="detail"
        >
          <Box mb={2}>
            <Typography variant="h3" component="h2" className={classes.text}>
              詳細情報
            </Typography>
            <Typography variant="subtitle1" className={classes.text}>
              以下は任意項目です。
            </Typography>
            <Typography variant="subtitle1" className={classes.title}>
              Twitter
            </Typography>
            <TextField
              inputRef={register}
              name="twitter"
              variant="outlined"
              className={classes.textFieldStyle}
              InputProps={{
                style: { fontSize: 16 },
                startAdornment: (
                  <InputAdornment position="start">@</InputAdornment>
                ),
              }}
              InputLabelProps={{ style: { fontSize: 16 } }}
            />
            <Typography variant="subtitle1" className={classes.title}>
              Instagram
            </Typography>
            <TextField
              inputRef={register}
              name="instagram"
              variant="outlined"
              className={classes.textFieldStyle}
              InputProps={{
                style: { fontSize: 16 },
                startAdornment: (
                  <InputAdornment position="start">@</InputAdornment>
                ),
              }}
              InputLabelProps={{ style: { fontSize: 16 } }}
            />
            <Typography variant="subtitle1" className={classes.title}>
              お住まいの都道府県
            </Typography>
            <SelectWithChips
              name="pref"
              control={control}
              list={shopMaster.pref ? shopMaster.pref.data : []}
            />
            <Typography variant="subtitle1" className={classes.title}>
              好きな古着ジャンル(複数選択可)
            </Typography>
            <MultiSelectWithChips
              name="fabThriftingGenre"
              control={control}
              setValue={setValue}
              list={
                shopMaster.thriftingGenre ? shopMaster.thriftingGenre.data : []
              }
            />
            <Typography variant="subtitle1" className={classes.title}>
              好きなファッションジャンル(複数選択可)
            </Typography>
            <MultiSelectWithChips
              name="fabFashionGenre"
              control={control}
              setValue={setValue}
              list={shopMaster.fashionGenre ? shopMaster.fashionGenre.data : []}
            />
            <Typography variant="subtitle1" className={classes.title}>
              自己紹介
            </Typography>
            <TextField
              inputRef={register}
              name="introduction"
              variant="outlined"
              multiline
              rows={6}
              className={classes.textFieldStyle}
              inputProps={{ style: { fontSize: 16 } }}
              InputLabelProps={{ style: { fontSize: 16 } }}
            />
            <Typography variant="subtitle1" className={classes.text}>
              以下は任意項目です。UIの最適化などに用います。他のユーザーに公開されることはありません。
            </Typography>
            <Typography variant="subtitle1" className={classes.title}>
              性別
            </Typography>
            <SelectWithChips
              label="性別"
              name="sex"
              control={control}
              list={accountMaster.sex ? accountMaster.sex.data : []}
            />
            <Typography variant="subtitle1" className={classes.title}>
              生年月日
            </Typography>
            <Grid container display="flex" spacing={1}>
              <Grid item xs={4}>
                <TextField
                  inputRef={register({
                    min: 1900,
                    max: 2100,
                  })}
                  name="birthYear"
                  type="number"
                  variant="outlined"
                  size="small"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                  error={Boolean(errors.birthYear)}
                  helperText={errors.birthYear && "値が不正です"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">年</InputAdornment>
                    ),
                    style: {
                      fontSize: 16,
                      // transform: "scale(calc(12 / 16))"
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputRef={register({
                    min: 1,
                    max: 12,
                  })}
                  type="number"
                  name="birthMonth"
                  variant="outlined"
                  size="small"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                  error={Boolean(errors.birthMonth)}
                  helperText={errors.birthMonth && "値が不正です"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">月</InputAdornment>
                    ),
                    style: {
                      fontSize: 16,
                      // transform: "scale(calc(12 / 16))"
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  inputRef={register({
                    min: 1,
                    max: 31,
                  })}
                  type="number"
                  name="birthDay"
                  variant="outlined"
                  size="small"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                  error={Boolean(errors.birthDay)}
                  helperText={errors.birthDay && "値が不正です"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">日</InputAdornment>
                    ),
                    style: {
                      fontSize: 16,
                      // transform: "scale(calc(12 / 16))"
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Typography variant="subtitle1" className={classes.title}>
              職業
            </Typography>
            <SelectWithChips
              name="occupation"
              control={control}
              list={
                accountMaster.occupation ? accountMaster.occupation.data : []
              }
            />
            <Typography variant="subtitle1" className={classes.title}>
              古着屋での来店あたりの平均予算
            </Typography>
            <SelectWithChips
              name="avgBudgetLine"
              control={control}
              list={
                accountMaster.avgBudgetLine
                  ? accountMaster.avgBudgetLine.data
                  : []
              }
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-around" spacing={1}>
          <Grid item xs={3}>
            <Button
              variant="contained"
              onClick={onClickBack}
              fullWidth
              color="secondary"
            >
              戻る
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              fullWidth
              onClick={onClickSkip}
              color="primary"
            >
              スキップ
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              fullWidth
              type="submit"
              form="detail"
              color="primary"
            >
              次へ
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </React.Fragment>
  );
}

export default DetailAccountInfo;
