import { Dialog } from "@material-ui/core";
import React from "react";
import FirstVisitDialogMain from "./main";

export default function FirstVisitDialog(props) {
  const { isOpen, handleClose } = props;

  return (
    <div>
      <Dialog fullWidth open={isOpen}>
        <FirstVisitDialogMain handleClose={handleClose} />
      </Dialog>
    </div>
  );
}
