import { Box } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../components/molecules/logoSvg";
import Avatar from "./avatar";
import Hamburger from "./hamburger";
import NotifyButton from "./notifyButton";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.header,
  },
  toolBar: {
    minHeight: "40px",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    // flexGrow: 1,
    position: "absolute",
    top: "50%",
    left: "50%",
    height: "100%",
    transform: "translate(-50%, -50%)",
    webkitTransform: "translate(-50%, -50%)",
    msTransform: "translate(-50%, -50%)",
    align: "center",
    alignContent: "center",
  },
  titleLink: {
    textDecoration: "none",
    align: "center",
    color: theme.palette.text.primary,
  },
  logo: {
    color: theme.palette.headerLogo,
  },
}));

export default function Header(props) {
  const { darkSetting, setDarkSetting } = props;

  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <Box>
              <Hamburger
                darkSetting={darkSetting}
                setDarkSetting={setDarkSetting}
              />
            </Box>
            <Link to="/">
              <Box className={classes.title}>
                <Logo color="#000000" height="100%" width="auto" />
              </Box>
            </Link>
            <Box display="flex" justifyItems="center">
              <NotifyButton />
              <Avatar />
            </Box>
          </Toolbar>
        </AppBar>
      </div>
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};
