import axios from "axios";
import { getAuth, getIdToken } from "firebase/auth";
import firebaseApp from "../firebase";
const instance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  async (config) => {
    let token;
    const auth = getAuth(firebaseApp);
    const account = auth.currentUser;
    if (account) {
      token = await getIdToken(account);
    }
    if (token) {
      config.headers.common["Authorization"] = `JWT ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const fetchBaseUrl = () => {
  let port;
  if (process.env.REACT_APP_PORT) {
    port = process.env.REACT_APP_PORT;
  } else {
    port = "";
  }
  const baseUrl =
    window.location.protocol + "//" + window.location.hostname + port;
  return baseUrl;
};

export default instance;
