import { Box, Button, Dialog, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {
  DialogContent,
  DialogTitle,
} from "../../components/molecules/dialogTemplete";
import {
  useGoBackOrTop,
  useHistoryPushWithState,
} from "../../logics/utils/useRouterWithState";
const useStyles = makeStyles((theme) => ({
  stepper: {
    padding: theme.spacing(0.5),
  },
  button: {
    margin: theme.spacing(1),
  },
  backButton: {
    margin: theme.spacing(1),
  },
  text: {
    margin: theme.spacing(2),
  },
}));

export default function ToLoginDialog(props) {
  const classes = useStyles();
  const { isOpen, handleClose, canClose } = props;
  const { historyPush } = useHistoryPushWithState();
  const { goBackOrTop } = useGoBackOrTop();
  const handleBack = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (canClose) {
      handleClose();
    } else {
      goBackOrTop();
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    historyPush("/signin");
  };

  return (
    <div>
      <Dialog fullWidth open={isOpen}>
        <DialogTitle id="customized-dialog-title">
          ログインが必要です
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="h6" className={classes.text}>
            引き続きこちらの機能を利用するにはログイン・会員登録が必要です。
            下記リンクからログインまたは会員登録を行ってください。
          </Typography>
          <Box align="center" alignContent="center" my={3}>
            <Button
              variant="contained"
              onClick={handleBack}
              className={classes.backButton}
            >
              戻る
            </Button>
            <Button
              variant="contained"
              component="a"
              href="/signin"
              onClick={handleClick}
              className={classes.button}
              color="primary"
            >
              ログイン・会員登録ページへ
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
