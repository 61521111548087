const initialShopDetail = {
  shopId: '',
  shopTopImages: [],
  tag: [],
  name: '',
  title: '',
  description: '',
  telNumber: '',
  zipCode: '',
  address: '',
  // eslint-disable-next-line
  geom: { coordinates: [,], },
  buisinessHours: '',
  avgBudget: null,
  hasEc: null,
  hasOriginal: null,
  hasBuying: null,
  usedRate: null,
  pref: null,
  area: null,
  closedDay: [],
  sexCategory: [],
  fashionGenre: [],
  thriftingGenre: [],
  country: [],
  countryToBuy: [],
  yearOfManufacture: [],
}

const initialNearByShopList = [
  {
    shopId: 1,
    listId: 0,
    geom: {
      coordinates: [139.6413117, 35.7083222]
    },
    img_url: "https://source.unsplash.com/2cdvYh6ULCs/300x300",
    name: 'init',
    message: 'init',
  },
]

const initialPostPhotoResults = [
  {
    url: "",
    priorityId: 0,
    postId: 0,
    shopId: "",
    shopName: "",
  },
]
const initialPostResults = [
  {
    id: null,
    postPhoto: initialPostPhotoResults,
    userPublicId: {
      photoUrl: null,
      publicId: null,
      displayName: null,
    },
    content: null,
    postType: 0,
    createdAt: null,
    shopId: null,
    shopName: null,
  },
]

export const initialPostDetail = {
  id: null,
  postPhoto: initialPostPhotoResults,
  userPublicId: {
    photoUrl: null,
    publicId: null,
    displayName: null,
  },
  content: null,
  postType: 0,
  createdAt: null,
  shopId: null,
  shopName: null,
}

const initialPostPhoto = {
  results: initialPostPhotoResults,
  nextUrl: "",
  count: 0,
  page: 1,
}

export const initialPost = {
  results: initialPostResults,
  nextUrl: "",
  count: 0,
  page: 1,
}

export const initialState = {
  shopDetail: { ...initialShopDetail },
  transShopDetail: { ...initialShopDetail },
  nearByShopList: initialNearByShopList,
  topPostPhoto: initialPostPhotoResults,
  topPost: initialPostResults,
  postPhoto: { ...initialPostPhoto },
  post: { ...initialPost },
  postComment: { results: [], count: 0 },
  postDetail: { ...initialPostDetail },
  isNearByShopListLoading: true,
  postLoading: true,
  postDetailLoading: true,
  topPostLoading: true,
  postPhotoLoading: true,
  postCommentLoading: true,
  topPostPhotoLoading: true,
  isLoading: true
}

export default initialState