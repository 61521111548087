import { Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  browserLocalPersistence,
  getAuth,
  inMemoryPersistence,
  setPersistence,
} from "firebase/auth";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CenterCircleLoading from "../../../components/molecules/centerCircleLoading";
import { DialogTitle } from "../../../components/molecules/dialogTemplete";
import {
  TmpPhotoProvider,
  usePhotoReducer,
} from "../../../logics/photo/tmpPhotoReducer";
import {
  checkEmailAndVerified,
  updateAccount,
  updateBackendEmail,
  useHandleFirstCancel,
  verifyAndUpdateEmail,
  verifyEmail,
} from "../../../logics/services/accounts";
import axios, { fetchBaseUrl } from "../../../logics/services/axios";
import firebaseApp from "../../../logics/services/firebase";
import { setAccount } from "../../../reducers/accountReducer";
import BasicAccountInfo from "./basic";
import DetailAccountInfo from "./detail";
import RegistedAccountInfo from "./registed";
import RegistAccountPhoto from "./registPhoto";
import Verify from "./verify";
const useStyles = makeStyles((theme) => ({
  stepper: {
    padding: theme.spacing(0.5),
  },
}));

const defaultBasicValues = {
  publicId: null,
  displayName: null,
  email: null,
};

const defaultDetailValues = {
  sex: null,
  birthYear: null,
  birthMonth: null,
  birthDay: null,
  pref: null,
  avgBudgetLine: null,
  fabFashionGenre: [],
  fabThriftingGenre: [],
  introduction: null,
  occupation: null,
};

export default function FirstVisitDialogMain(props) {
  const classes = useStyles();
  const { handleClose } = props;
  const dispatch = useDispatch();
  const [basicState, setBasicState] = useState(defaultBasicValues);
  const [sentEmail, setSentEmail] = useState(false);
  const [registError, setRegistError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isBasicLoading, setBasicLoading] = useState(false);
  const [providerId, setProviderId] = useState(null);
  const [detailState, setDetailState] = useState(defaultDetailValues);
  const [photoState, dispatchPhoto] = usePhotoReducer();
  const isDetective = useSelector((state) => state.account.isDetective);
  const fUser = useSelector((state) => state.account.firebaseUser);
  const auth = getAuth(firebaseApp);

  const steps = ["基本情報", "詳細情報", "画像", "登録確認"];
  const [activeStep, setActiveStep] = useState(0);
  const isStepOptional = (step) => {
    return [1, 2].includes(step);
  };

  const handleSubmitBasic = async (data, setError) => {
    setBasicLoading(true);
    const publicId = data.publicId;
    const response = await axios
      .get(`${fetchBaseUrl()}/api/check_id/${publicId}/`)
      .catch((err) => err.response);
    if (response.status === 200) {
      if (response.data.exist) {
        setError("publicId", {
          type: "manual",
          message: "すでに存在するIDです",
        });
      } else {
        setBasicState(data);
        setActiveStep(1);
      }
    } else {
      setError("publicId", {
        type: "manual",
        message:
          "IDチェック中にエラーが発生しました。通信状況などを確認してください。",
      });
    }
    setBasicLoading(false);
  };

  const handleCancel = useHandleFirstCancel();

  const handleCancelAndClose = async () => {
    setBasicLoading(true);
    await handleCancel();
    setBasicLoading(false);
    handleClose();
  };

  const handleToBasicInfo = () => {
    setActiveStep(0);
  };

  const handleSubmitDetail = (data) => {
    setDetailState(data);
    setActiveStep(2);
  };

  const handleToRegistPhoto = () => {
    setActiveStep(2);
  };

  const handleToDetailInfo = () => {
    setActiveStep(1);
  };

  const handleToVerify = () => {
    setRegistError(false);
    setActiveStep(3);
  };

  const handleRegist = async (data) => {
    setLoading(true);

    // 画像のupload
    const hasError = await uploadImg();
    if (hasError) {
      setRegistError(
        "登録に失敗しました。申し訳ありませんが通信状態を確認の上再度お試しください。"
      );
      setLoading(false);
      return null;
    }

    //メールアドレスの確認
    const resp = await updateEmail();
    if (resp.err) {
      setRegistError(resp.message);
      setLoading(false);
      return null;
    }

    // UserProfileの設定
    const userProfileResp = await postUserProfile();
    if (userProfileResp.err) {
      setRegistError(userProfileResp.message);
      setLoading(false);
      return null;
    }

    setLoading(false);
    setPersistence(auth, browserLocalPersistence);
    dispatch(setAccount());
    setActiveStep(4);
  };

  const uploadImg = async () => {
    let hasError = false;
    const imgState = photoState && photoState[0];
    const uid = fUser && fUser.uid;
    if (imgState && uid) {
      const imgBlob = await fetch(imgState.cropedImage).then((r) => r.blob());
      const storage = getStorage(firebaseApp);

      const imgRef = ref(storage, `photo/users/${uid}/top/${imgState.name}`);
      await uploadBytes(imgRef, imgBlob).catch((e) => (hasError = true));
    }
    return hasError;
  };

  //responseの内部実装を知っていることになっているのでリファクタしたい
  const updateEmail = async () => {
    let verified = false;
    let response = { err: false, message: "" };
    if (basicState.email) {
      if (basicState.email !== fUser.email) {
        //新しくメールアドレスを設定する場合
        response = await verifyAndUpdateEmail(basicState.email);
        verified = false;
        setSentEmail(true); //ここでいいのか問題はあるけどまあ
      } else if (fUser.emailVerified) {
        //firebaseのメールアドレスが認証済の場合
        response = await updateBackendEmail(basicState.email);
        verified = true;
      } else {
        // firebaseのメールアドレスが未認証の場合
        response = await verifyEmail();
        verified = false;
        setSentEmail(true); //ここでいいのか問題
      }
    } else {
      verified = false;
    }

    if (response.err) {
      return response;
    } else {
      //emailとverifyが正しくバックエンドに登録されているか確認
      response = await checkEmailAndVerified(basicState.email, verified);
      return response;
    }
  };

  const postUserProfile = async () => {
    const userProfile = {
      userPublic: {
        publicId: basicState.publicId,
        displayName: basicState.displayName,
        sex: detailState.sex,
        birthYear: detailState.birthYear ? detailState.birthYear : null,
        birthMonth: detailState.birthMonth ? detailState.birthMonth : null,
        birthDay: detailState.birthDay ? detailState.birthDay : null,
        pref: detailState.pref,
        fabFashionGenre: detailState.fabFashionGenre,
        fabThriftingGenre: detailState.fabThriftingGenre,
        introduction: detailState.introduction,
        twitter: detailState.twitter,
        instagram: detailState.instagram,
        profilePhotoName:
          photoState && photoState[0] ? photoState[0].name : null,
      },
      userDetail: {
        avgBudgetLine: detailState.avgBudgetLine,
        occupation: detailState.occupation,
      },
    };

    const response = await updateAccount(userProfile);

    return response;
  };

  //fUserが変わった際のinitialize
  useEffect(() => {
    if (fUser) {
      console.log(fUser);
      const newBasicState = Object.assign({}, defaultBasicValues);
      const newDetailState = Object.assign({}, defaultDetailValues);
      newBasicState.email = fUser.email;
      setBasicState(newBasicState);
      setDetailState(newDetailState);
      setProviderId(fUser.providerData[0].providerId);
      if (isDetective) {
        setPersistence(auth, browserLocalPersistence);
      } else {
        setActiveStep(0);
        setPersistence(auth, inMemoryPersistence);
      }
    }
  }, [fUser, isDetective, auth]);

  return (
    <TmpPhotoProvider state={photoState} dispatch={dispatchPhoto}>
      <DialogTitle id="customized-dialog-title">
        会員情報登録
        <Stepper
          activeStep={activeStep}
          classes={{ root: classes.stepper }}
          alternativeLabel
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">(省略可)</Typography>
              );
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </DialogTitle>

      {activeStep === 0 ? (
        <BasicAccountInfo
          onClickBack={handleCancelAndClose}
          onSubmit={handleSubmitBasic}
          defaultValues={basicState}
          providerId={providerId}
          isLoading={isBasicLoading}
        />
      ) : activeStep === 1 ? (
        <DetailAccountInfo
          onClickBack={handleToBasicInfo}
          onClickSkip={handleToRegistPhoto}
          onClickNext={handleSubmitDetail}
          defaultValues={detailState}
        />
      ) : activeStep === 2 ? (
        <RegistAccountPhoto
          onClickBack={handleToDetailInfo}
          onClickSkip={handleToVerify}
          onClickNext={handleToVerify}
        />
      ) : activeStep === 3 ? (
        <Verify
          onClickBack={handleToBasicInfo}
          onClickNext={handleRegist}
          basic={basicState}
          detail={detailState}
          photo={photoState}
          registError={registError}
        />
      ) : activeStep === 4 ? (
        <RegistedAccountInfo onClick={handleClose} sentEmail={sentEmail} />
      ) : null}
      <CenterCircleLoading isLoading={isLoading}></CenterCircleLoading>
    </TmpPhotoProvider>
  );
}
