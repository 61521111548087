import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAccountMaster
} from '../../../reducers/accountReducer'
import {
  setShopMaster
} from '../../../reducers/shopMasterReducer'


export default function useMaster() {
  const dispatch = useDispatch()
  const shopMaster = useSelector(state => state.master)
  const accountMaster = useSelector(state => state.account.accountMaster)

  useEffect(() => {
    dispatch(setShopMaster())
    dispatch(setAccountMaster())
  }, [dispatch])

  return {
    shopMaster,
    accountMaster,
  }
}
