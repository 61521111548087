import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
const baseLightTheme = createTheme({
  typography: {
    fontFamily: '"Noto Sans JP"',
    // fontWeight: 700,
    fontSize: 12,
    lineHeight: "1.8rem",
  },
  palette: {
    type: "light", //全体がlightかdarkかを選べる
    primary: {
      light: "#ffe54c",
      main: "#ffb300",
      dark: "#c68400",
    },
    header: "#ffc107",
    headerLogo: "#000000",
    brand: "#ffc107",

    map: {
      marker: "#000000dd", //cyan 800 dark
      selectedMarker: "#ff6f00DD", //amber 900
    },
    secondary: {
      //cyan 800
      light: "#4fb3bf",
      main: "#00838f",
      dark: "#005662",
    },
    text: {
      inherit: "#ffffff",
    },
    background: {
      defaultDark: "#F1F1F1",
      gradient: "rgba(255,255,255,0.5)",
      gradientDark: "rgba(255,255,255,0.7)",
      subPaper: "#F1F1F1FF",
      opacitySubPaper: "#F1F1F100",
    },
  },
});

const baseDarkTheme = createTheme({
  typography: {
    fontFamily: '"Noto Sans JP"',
    // fontWeight: 700,
    fontSize: 12,
  },
  palette: {
    type: "dark", //全体がlightかdarkかを選べる
    brand: "#ffc107",
    header: "#ffc107",
    headerLogo: "#000000",

    primary: {
      light: "#ffcf33",
      main: "#FFD54F",
      dark: "#FF6F00",
    },
    error: {
      main: "#CF6679",
    },
    secondary: {
      light: "#b4ffff",
      main: "#80deea",
      dark: "#4bacb8",
    },
    map: {
      marker: "#f8fdffF0", //secondary light
      selectedMarker: "#FFC107F9",
    },

    text: {
      primary: "#f0f0f0",
      inherit: "#101010",
    },
    background: {
      default: "#101010",
      defaultDark: "#1F1F1F",
      paper: "#2F2F2F",
      gradient: "rgba(0,0,0,0.5)",
      gradientDark: "rgba(0,0,0,0.7)",
      subPaper: "#3F3F3FFF",
      opacitySubPaper: "#3F3F3F00",
      // gradient: 'linear-gradient(45deg, #000000 10%, #303F4F 90%)'
      // gradient: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
    },
  },
});

export const darkTheme = responsiveFontSizes(baseDarkTheme);
export const lightTheme = responsiveFontSizes(baseLightTheme);
