export const defaultItems = {
  shopFeatures: {
    name: "店舗形態",
    data: [
      { id: "hasEc", name: "EC有り" },
      { id: "hasBuying", name: "買取有り" },
      { id: "hasOriginal", name: "オリジナルブランド有り" },
    ]
  },
  budget: {
    name: "平均予算",
    settings: {
      defaultValue: [0, 50000],
      step: 1000,
      min: 0,
      max: 50000,
      valueLabel: {
        50000: "50000~"
      },
      dontFilterValue: [0, 50000],
    },
    minQuery: "minBudget",
    maxQuery: "maxBudget",
  },
  yearOfManufacture: {
    name: "年代",
    settings: {
      defaultValue: [1890, 2010],
      step: 10,
      min: 1890,
      max: 2010,
      valueLabel: {
        1890: "~1900",
        2010: "2000~"
      },
      dontFilterValue: [1890, 2010],
    },
    minQuery: "minYear",
    maxQuery: "maxYear",
  },
  tag: { name: "タグ検索" },
  search: { name: "キーワード検索", label: "店舗名 エリア など" },
  mapBoundsSearch: { name: "地図表示領域から探す" },
}

export const filterQueries = {
  pref: new Set(),
  area: new Set(),
  closedDay: new Set(),
  thriftingGenre: new Set(),
  fashionGenre: new Set(),
  sexCategory: new Set(),
  country: new Set(),
  countryToBuy: new Set(),
  minYear: 1890,
  maxYear: 2010,
  point: {},
  dist: 2000,
  in_bbox: {},
  search: "",
  tag: new Set(),
  minBudget: 0,
  maxBudget: 50000,
  hasEc: null,
  hasBuying: null,
  hasOriginal: null,
  mapBoundsSearch: false,
  page: 1,
}

export const defaultFilters = {
  pref: {
    name: "都道府県",
    data: [
      // {
      //   id: 1,
      //   name: 1
      // }
    ],
  },
  area: {
    name: "エリア",
    data: []
  },
  closedDay: {
    name: "定休日",
    data: []
  },
  thriftingGenre: {
    name: "古着ジャンル",
    data: []
  },
  fashionGenre: {
    name: "ファッションジャンル",
    data: []
  },
  sexCategory: {
    name: "性別カテゴリ",
    data: []
  },
  country: {
    name: "国",
    data: []
  },
  countryToBuy: {
    name: "買い付け場所",
    data: []
  },
  //以下masterなし
  ...defaultItems,
}

export const initialState = {
  filters: { ...defaultFilters },
  filterQueries: { ...filterQueries },
  isInitFilters: false,
  isInitQueries: false,
  filterCount: 0,
  researchCount: 0
}

export default initialState