import initialState from './filterReducerInitial'


const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_FILTER':
      return {
        ...state,
        filters: Object.assign(state.filters, action.data),
        isInitFilters: true
      }
    case 'SET_FILTERS':
      return { ...state, filters: action.data }
    case 'SET_QUERIES':
      return {
        ...state,
        filterQueries: action.data,
        isInitQueries: true
      }
    case 'SET_PAGE_QUERY':
      return {
        ...state,
        filterQueries: {
          ...state.filterQueries, page: action.data
        }
      }
    case 'CLEAR_QUERIES':
      return {
        ...state,
        filterQueries: initialState.filterQueries,
        isInitQueries: true
      }
    case 'UNSET_QUERIES':
      return {
        ...state,
        isInitQueries: false
      }
    case 'SET_FILTER_COUNT':
      return { ...state, filterCount: action.data }

    default:
      return state
  }
}

export const initFilter = (filterItem) => {
  return {
    type: 'INIT_FILTER',
    data: filterItem,
  }
}

export const setFilters = (filters) => {
  return {
    type: 'SET_FILTERS',
    data: filters
  }
}

export const setFilterQueries = (queries) => {
  return {
    type: 'SET_QUERIES',
    data: queries
  }
}
export const clearQueries = () => {
  return {
    type: 'CLEAR_QUERIES',
  }
}

export const unsetQueries = () => {
  return {
    type: 'UNSET_QUERIES',
  }
}

export const setFilterCount = (count) => {
  return {
    type: 'SET_FILTER_COUNT',
    data: count
  }
}
export const setPage = (pageId) => {
  return {
    type: 'SET_PAGE_QUERY',
    data: pageId
  }
}

export default filterReducer