import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { DialogContent, DialogTitle } from '../../../components/molecules/dialogTemplete';
const useStyles = makeStyles((theme) => ({
    backButton: {
        margin: theme.spacing(1)
    },
    text: {
        margin: theme.spacing(2)
    }
}));


export default function SendVerifyMailSuccess(props) {
    const classes = useStyles()
    const { handleClick } = props
    return (
        < React.Fragment>
            <DialogTitle id="customized-dialog-title" >
                メールを再送信しました
                </DialogTitle>
            <DialogContent dividers>
                <Typography variant="subtitle1" className={classes.text}>
                    お送りしたメールのリンクを開き、認証を完了してください。
                </Typography>
                <Box align="center" alignContent="center" my={3}>
                    <Button variant="contained" onClick={handleClick} className={classes.backButton}>
                        戻る
                    </Button>
                </Box>
            </DialogContent>
        </React.Fragment>
    );
}