import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import CenterCircleLoading from "./components/molecules/centerCircleLoading";
import FirstVisitDialogIfNotDetective from "./containers/modules/firstVisitDialog/index.js";
import Header from "./containers/modules/header/index.js";
import Init from "./containers/modules/init";
import NotFound from "./containers/pages/notFound.js";
import OnlyAuthenticatedRoute from "./logics/services/accounts/authenticatedRouter";
import { useDarkTheme } from "./logics/services/darkmode";
const ShopRegisterPhoto = lazy(() => import("./containers/pages/photoRegist"));
const NoticeListPage = lazy(() =>
  import("./containers/pages/noticeListPage.js")
);
const Notification = lazy(() => import("./containers/pages/notification"));
const ContactForm = lazy(() => import("./containers/pages/contact.js"));
const FAQ = lazy(() => import("./containers/pages/faq.js"));
const FeedbackForm = lazy(() => import("./containers/pages/feedback.js"));
const Accmgmt = lazy(() => import("./containers/pages/accmgmt/index.js"));
//
const About = lazy(() => import("./containers/pages/about.js"));
const SignInScreen = lazy(() => import("./containers/pages/login/signIn.js"));
const MyPageRouter = lazy(() => import("./containers/pages/mypage/index.js"));
const UserPageRouter = lazy(() =>
  import("./containers/pages/userPage/index.js")
);
const Notice = lazy(() => import("./containers/pages/notice.js"));
const PrivacyPolicy = lazy(() => import("./containers/pages/privacypolicy.js"));
const PostGuideline = lazy(() => import("./containers/pages/postGuideline.js"));
const ReccomendEnv = lazy(() => import("./containers/pages/recenv.js"));
const ShopDetail = lazy(() => import("./containers/pages/shopDetail/index.js"));
const Search = lazy(() => import("./containers/pages/shopList/index.js"));
const ShopRegisterForm = lazy(() => import("./containers/pages/shopRegist"));
const Top = lazy(() => import("./containers/pages/top"));
const PostList = lazy(() => import("./containers/pages/postList"));
const Tos = lazy(() => import("./containers/pages/tos.js"));

export default function App() {
  const { theme, darkSettingState, setDarkSettingState } = useDarkTheme();
  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Header
          darkSetting={darkSettingState}
          setDarkSetting={setDarkSettingState}
        />
        {/* <AlertBar
          title="利用規約を変更しました。内容をご確認ください。"
          linkTitle="利用規約"
          defaultOpen={false}
          link="/tos"
        /> */}
        <FirstVisitDialogIfNotDetective />
        <Init />
        <Suspense fallback={<CenterCircleLoading isLoading={true} />}>
          <Switch>
            <Route exact path="/">
              <Top />
            </Route>
            <Route path="/search/:filterKey?/:itemKey?">
              <Search />
            </Route>
            <Route path="/shop/:shopId">
              <ShopDetail />
            </Route>
            <Route exact path="/signin">
              <SignInScreen />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/contact">
              <ContactForm />
            </Route>
            <Route exact path="/notice">
              <NoticeListPage />
            </Route>
            <Route path="/notice/:noticeSlug">
              <Notice />
            </Route>
            <Route path="/faq">
              <FAQ />
            </Route>
            <Route path="/recenv">
              <ReccomendEnv />
            </Route>
            <Route path="/feedback">
              <FeedbackForm />
            </Route>
            <Route path="/tos">
              <Tos />
            </Route>
            <Route path="/privacypolicy">
              <PrivacyPolicy />
            </Route>
            <Route path="/postGuideline">
              <PostGuideline />
            </Route>
            <Route path="/user">
              <UserPageRouter />
            </Route>
            <Route path="/postList">
              <PostList />
            </Route>
            <OnlyAuthenticatedRoute exact path="/shopRegist">
              <ShopRegisterForm />
            </OnlyAuthenticatedRoute>
            <OnlyAuthenticatedRoute path="/mypage">
              <MyPageRouter />
            </OnlyAuthenticatedRoute>
            <OnlyAuthenticatedRoute path="/notification">
              <Notification />
            </OnlyAuthenticatedRoute>
            <Route exact path="/accmgmt">
              <Accmgmt />
            </Route>
            <Route exact path="/whatadangomushi">
              <ShopRegisterPhoto />
            </Route>
            <Route exact path="/notfound">
              <NotFound />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Suspense>
      </MuiThemeProvider>
    </React.Fragment>
  );
}
