// 絶対に初回走るな
import { useEffect, useRef } from "react";

function usePrevState(value) {
    const ref = useRef(null);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export default usePrevState