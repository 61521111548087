import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  initFilter
} from '../../../reducers/filterReducer'
import { defaultFilters } from '../../../reducers/filterReducerInitial'

export default function useInitFilters() {
  const dispatch = useDispatch()
  const shopMaster = useSelector(state => state.master)

  useEffect(() => {
    if (shopMaster && Object.keys(shopMaster).length) {
      const newFilterItems = Object.assign(defaultFilters, shopMaster)
      dispatch(initFilter(newFilterItems))
    }
  }, [dispatch, shopMaster])
}
