import { initialPost } from "./shopDetailReducerInitial";
import { initialShopList } from "./shopListReducerInitial";
export const initialState = {
  likeShopList: [...initialShopList],
  likeShopListCount: 0,
  isLikeShopListLoading: true,
  likePosts: { ...initialPost },
  likePostLoading: true,
  myPosts: { ...initialPost },
  myPostLoading: true,
  followList: [],
  followerList: [],
  followListLoading: true,
};

export default initialState;
