import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "../../../components/molecules/loadingButton";

const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  textFieldStyle: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  rawEmail: {
    width: "100%",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const READ_ONLY_EMAIL_PROVIDERS = ["google.com", "password"];
function BasicAccountInfo(props) {
  const classes = useStyles();
  const { onSubmit, defaultValues, onClickBack, providerId, isLoading } = props;
  const { control, handleSubmit, register, setValue, setError, errors } =
    useForm({
      defaultValues: defaultValues,
    });

  useEffect(() => {
    if (defaultValues.email) {
      setValue("email", defaultValues.email);
    }
  }, [providerId, defaultValues.email, setValue]);

  return (
    <React.Fragment>
      <DialogContent dividers>
        <form
          id="basic"
          onSubmit={handleSubmit((data) => onSubmit(data, setError))}
          className={classes.form}
        >
          <Typography variant="h5" component="h2" className={classes.text}>
            基本情報
          </Typography>
          <Typography variant="subtitle1" className={classes.text}>
            会員機能のご利用には、ユーザーIDと表示名の登録が必要です。
            ユーザーIDは後から変更できません。
          </Typography>
          <Typography variant="subtitle1" className={classes.title}>
            ユーザーID(必須)
          </Typography>
          <TextField
            inputRef={register({
              maxLength: {
                value: 50,
                message: "ユーザーIDは50文字以内にしてください。",
              },
              pattern: {
                value: /^[0-9a-zA-Z_-]+$/,
                message:
                  "ユーザーIDは英数字、ハイフン(-)、アンダースコア(_)のみ使用してください。",
              },
            })}
            name="publicId"
            variant="outlined"
            required
            onKeyPress={(e) => {
              if (e.key === "Enter") e.preventDefault();
            }}
            error={Boolean(errors.publicId)}
            helperText={errors.publicId && errors.publicId.message}
            className={classes.textFieldStyle}
            inputProps={{ style: { fontSize: 16 } }}
            InputLabelProps={{ style: { fontSize: 16 } }}
          />
          <Typography variant="subtitle1" className={classes.title}>
            表示名(必須)
          </Typography>
          <TextField
            inputRef={register({
              maxLength: {
                value: 50,
                message: "表示名は50文字以内にしてください。",
              },
            })}
            name="displayName"
            variant="outlined"
            required
            onKeyPress={(e) => {
              if (e.key === "Enter") e.preventDefault();
            }}
            error={Boolean(errors.displayName)}
            helperText={errors.displayName && errors.displayName.message}
            className={classes.textFieldStyle}
            inputProps={{ style: { fontSize: 16 } }}
            InputLabelProps={{ style: { fontSize: 16 } }}
          />
          <Typography variant="subtitle1" className={classes.title}>
            メールアドレス
          </Typography>
          <Controller
            control={control}
            name="email"
            render={({ value, onChange }) => {
              return READ_ONLY_EMAIL_PROVIDERS.includes(providerId) ? (
                <Box mb={2} mt={1}>
                  <Typography>{defaultValues.email}</Typography>
                </Box>
              ) : (
                <TextField
                  value={value}
                  onChange={onChange}
                  variant="outlined"
                  type="email"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                  className={classes.textFieldStyle}
                  error={Boolean(errors.email)}
                  helperText={errors.email && "値が不正です"}
                  inputProps={{ style: { fontSize: 16 } }}
                  InputLabelProps={{ style: { fontSize: 16 } }}
                />
              );
            }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-around" spacing={1}>
          <Grid item xs={4}>
            <LoadingButton
              variant="contained"
              onClick={onClickBack}
              fullWidth
              isLoading={isLoading}
            >
              やめる
            </LoadingButton>
          </Grid>
          <Grid item xs={8}>
            <LoadingButton
              variant="contained"
              size="large"
              fullWidth
              type="submit"
              form="basic"
              color="primary"
              isLoading={isLoading}
            >
              次へ
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </React.Fragment>
  );
}

export default BasicAccountInfo;
