import {
  getAuth,
  sendEmailVerification,
  verifyBeforeUpdateEmail,
} from "firebase/auth";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CenterCircleLoading from "../../../components/molecules/centerCircleLoading";
import FirstVisitDialog from "../../../containers/modules/firstVisitDialog/dialog";
import ToLoginDialog from "../../../containers/modules/toLoginDialog";
import ToVerifyEmailDialog from "../../../containers/modules/toVerifyEmailDialog";
import useIsOpen from "../../../logics/utils/isOpen";
import { setAccount, setLogout } from "../../../reducers/accountReducer";
import usePrevState from "../../utils/usePrevState";
import axios, { fetchBaseUrl } from "../axios";
import firebaseApp from "../firebase";

export default function OnlyAuthenticatedUser() {
  const [isLoginOpen, handleLoginOpen, handleLoginClose] = useIsOpen(false);
  const [isRegistOpen, handleRegistOpen, handleRegistClose] = useIsOpen(false);
  const [isVerifyOpen, handleVerifyOpen, handleVerifyClose] = useIsOpen(false);
  const account = useSelector((state) => state.account);
  const firebaseUser = account.firebaseUser;
  const isDetective = account.isDetective;
  const dispatch = useDispatch();
  const isLoading = account.isLoading;
  const prevFirebaseUser = usePrevState(firebaseUser);
  const history = useHistory();
  useEffect(() => {
    //ログアウトした場合トップへ
    if (!isLoading) {
      if (prevFirebaseUser && !firebaseUser) {
        history.push("/");
      } else if (!firebaseUser) {
        //未ログインの場合ログインダイアログ
        handleLoginOpen();
      } else if (!isDetective) {
        //仮登録の場合登録ダイアログ
        handleRegistOpen();
      } else if (
        firebaseUser.providerData[0].providerId === "password" &&
        !firebaseUser.emailVerified
      ) {
        handleVerifyOpen();
      } else {
        handleLoginClose();
      }
    }
  }, [
    firebaseUser,
    isDetective,
    isLoading,
    history,
    handleLoginClose,
    handleLoginOpen,
    handleRegistOpen,
    handleVerifyOpen,
    prevFirebaseUser,
  ]);

  useEffect(() => {
    if (firebaseUser && !account.account.userPublic.publicId)
      dispatch(setAccount());
    //eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <CenterCircleLoading isLoading={isLoading} />
      <ToLoginDialog
        isOpen={isLoginOpen}
        handleOpen={handleLoginOpen}
        handleClose={handleLoginClose}
        canClose={false}
      />
      <FirstVisitDialog
        isOpen={isRegistOpen}
        handleOpen={handleRegistOpen}
        handleClose={handleRegistClose}
      />
      <ToVerifyEmailDialog
        isOpen={isVerifyOpen}
        handleOpen={handleVerifyOpen}
        handleClose={handleVerifyClose}
      />
    </React.Fragment>
  );
}

export const useHandleCancel = (setError) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCancel = async () => {
    try {
      await axios.delete(`${fetchBaseUrl()}/api/f_account/`);
    } catch (error) {
      const message =
        "エラーが発生しました。申し訳ありませんが時間をおいて再度お試しいただくか、問い合わせよりご連絡ください。";
      if (setError) {
        setError(message);
      }
      return null;
    }
    dispatch(setLogout());
    history.push("/");
  };

  return handleCancel;
};

export const useHandleFirstCancel = (setError) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleCancel = async () => {
    try {
      await axios.delete(`${fetchBaseUrl()}/api/first_account/`);
    } catch (error) {
      const message =
        "エラーが発生しました。申し訳ありませんが時間をおいて再度お試しいただくか、問い合わせよりご連絡ください。";
      if (setError) {
        setError(message);
      }
      return null;
    }
    dispatch(setLogout());
    history.push("/");
  };

  return handleCancel;
};

// firebaseのemailをupdateしverify mail を送信
export const verifyAndUpdateEmail = async (email) => {
  const auth = getAuth(firebaseApp);

  let response = {
    err: false,
    message: "",
  };
  try {
    await verifyBeforeUpdateEmail(auth.currentUser, email);
  } catch (err) {
    response.err = true;
    switch (err.code) {
      case "auth/requires-recent-login":
        response.message =
          "アカウントの有効期限が切れています。お手数ですが再度ログインし直してください。";
        break;
      case "auth/invalid-email":
        response.message =
          "不正なメールアドレスです。メールアドレスの形式を見直してください。";
        break;
      case "auth/email-already-in-use":
        response.message =
          "このメールアドレスはすでに使用されています。メールアドレスを変更しやり直してください。";
        break;
      default:
        response.message =
          "申し訳ありませんが通信状態を確認の上再度お試しください。";
    }
    return response;
  }
  response = await updateBackendEmail(email); //バックエンドのemailを変更
  return response;
};

// firebaseのverify mail を送信
export const verifyEmail = async () => {
  const auth = getAuth(firebaseApp);

  let response = {
    err: false,
    message: "",
  };
  const email = auth.currentUser.email;
  try {
    await sendEmailVerification(auth.currentUser);
  } catch (err) {
    // actionCodeSettingsを与えた場合のエラーしかなかった
    // https://firebase.google.com/docs/reference/js/firebase.User#sendemailverification
    response.err = true;
    response.message =
      "メール送信中にエラーが発生しました。申し訳ありませんが通信状態を確認の上再度お試しください。";
    return response;
  }
  response = await updateBackendEmail(email);
  return response;
};

//認証ずみのメールアドレスをバックエンドのDBへ保存
export const updateBackendEmail = async (email) => {
  let response = {
    err: false,
    message: "",
  };
  try {
    await axios.patch(`${fetchBaseUrl()}/api/account/email/`, { email: email });
  } catch (error) {
    response.err = true;
    response.message =
      "エラーが発生しました。申し訳ありませんが通信状態を確認の上再度お試しください。";
  }
  return response;
};

//認証ずみのメールアドレスをバックエンドのDBへ保存
export const checkEmailAndVerified = async (email, verified) => {
  let response = {
    err: false,
    message: "",
  };
  try {
    const resp = await axios.get(`${fetchBaseUrl()}/api/account/email/`);
    if (resp.data.email === email && resp.data.emailVerified === verified) {
      return response;
    } else {
      response.err = true;
      response.message =
        "メールアドレス登録中にエラーが発生しました。申し訳ありませんが通信状態を確認の上再度お試しください。";
    }
  } catch (error) {
    response.err = true;
    response.message =
      "メールアドレス登録中にエラーが発生しました。申し訳ありませんが通信状態を確認の上再度お試しください。";
  }
  return response;
};

export const updateAccount = async (data) => {
  let response = {
    err: false,
    message: "",
  };
  try {
    await axios.patch(`${fetchBaseUrl()}/api/account/`, data);
  } catch (err) {
    response.err = true;
    const data = err.response.data;
    const status = err.response.status;
    if (status === 400 && data && data.userPublic && data.userPublic.publicId) {
      response.message =
        "すでにこのユーザーIDは利用されています。お手数ですが再度設定してください。";
    } else {
      response.message =
        "エラーが発生しました。お手数ですが通信状況をお確かめの上再度登録ください。";
    }
  }
  return response;
};

// 最初にアカウント作成をする
// エラーがでた場合もここですることはさしてない気がする
export const createDBUser = async () => {
  let response = {
    err: false,
    message: "",
  };
  try {
    const resp = await axios.get(`${fetchBaseUrl()}/api/account/check/`);
    if (resp.status === 200) {
      return response;
    } else {
      response.err = true;
    }
  } catch (error) {
    response.err = true;
  }
  return response;
};
