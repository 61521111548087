import { Box, Container, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    width: "100vw",
    backgroundColor: theme.palette.background.default
  },
  linkList: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  footerLink: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  titleLink: {
    align: "center",
    color: theme.palette.text.primary
  },
  region: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1)
  }
}));

function FooterLink(props) {
  const { title, to, count } = props
  let color = "textPrimary"
  let underline = 'always'
  if (count === 0) {
    color = "textSecondary"
    underline = "none"
  }
  const classes = useStyles()
  return (
    <div className={classes.footerLink}>
      <Typography variant="subtitle2" className={classes.title}>
        <Link
          to={to}
          component={RouterLink}
          color={color}
          underline={underline}
          className={classes.titleLink}
        >
          {title}
        </Link>
      </Typography>
    </div>
  )
}

// function RegionFilterPrefList(props) {
//   const { id, prefList } = props
//   const filterFunction = (pref) => {
//     return pref.region === id
//   }

//   if (prefList && prefList.data.length) {
//     const filteredList = prefList.data.filter(filterFunction)
//     return (
//       filteredList.map((pref) => {
//         return (
//           <FooterLink
//             count={pref.shopCount}
//             title={`${pref.name}の古着屋(${pref.shopCount})`}
//             to={`/search/pref/${pref.id}`}
//             key={pref.id} />)
//       })
//     )
//   }
//   return null
// }

function SimpleList(props) {
  const { list, label } = props
  if (list && list.data.length) {
    return (
      list.data.map((item) => {
        return (
          <FooterLink
            // count={item.shopCount}
            title={`${item.name}の古着屋`}
            to={`/search/${label}/${item.id}?type=map`}
            key={item.id} />
        )
      })
    )
  }
  return null

}

export default function CategoryFooter(props) {
  const classes = useStyles()
  // const regionList = useSelector(state => state.master.region)
  // const prefList = useSelector(state => state.master.pref)
  const areaList = useSelector(state => state.master.area)
  const thriftingList = useSelector(state => state.master.thriftingGenre)
  const fashionList = useSelector(state => state.master.fashionGenre)

  return (
    <Container maxWidth="md" className={classes.root}>
      <Typography variant="h5" component="h2" >
        エリア一覧から古着屋を探す
      </Typography>
      <Box mb={3} mt={3} display="flex" flexWrap="wrap" >
        <SimpleList list={areaList} label="area" />
      </Box>
      <Typography variant="h5" component="h2" >
        古着ジャンル一覧から探す
      </Typography>
      <Box mb={3} mt={3} display="flex" flexWrap="wrap" >
        <SimpleList list={thriftingList} label="thriftingGenre" />
      </Box>
      <Typography variant="h5" component="h2" >
        ファッションジャンル一覧から探す
      </Typography>
      <Box mb={3} mt={3} display="flex" flexWrap="wrap" >
        <SimpleList list={fashionList} label="fashionGenre" />
      </Box>
      {/* <Typography variant="h4" component="h2" >
        都道府県一覧から古着屋を探す
      </Typography>

      {regionList && regionList.data.length && regionList.data.map((region) => {
        return (
          <div className={classes.region} key={region.id}>
            <Typography variant="h6" component="h3" >
              {region.name}
            </Typography>
            <div className={classes.linkList}>
              <RegionFilterPrefList id={region.id} prefList={prefList} />
            </div>
          </div>
        )
      })
      } */}
    </Container>
  );
}


