import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RemoveIcon from '@material-ui/icons/Remove';
import React from 'react';
import { NewLineTypography } from './newLineTypography';

const useStyles = makeStyles((theme) => ({
  chips: {
    // marginTop: theme.spacing(0.5),
    // marginBottom: theme.spacing(0.5),
    display: 'flex',
    flexWrap: 'wrap',
    // overflow: "hidden"
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  category: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    width: "100%",
    display: "flex",
    flexDirection: "row"
  },
  simpleCategory: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    width: "100%",
    display: "flex",
    flexDirection: "row"
  },
  sliderCategory: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  categoryTitle: {
    display: "flex",
    flexDirection: "row",
    width: "45%",
    paddingRight: theme.spacing(2),
    marginTop: "auto",
    marginBottom: "auto",
  },
  simpleCategoryTitle: {
    width: "30%"
  },
  simpleCategoryContent: {
    width: "70%"
  },
  categoryContent: {
    width: "50%"
  },
  categoryChips: {
    width: "50%",
  },
  slider: {
    marginTop: theme.spacing(5),
    width: "50%",
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(1)
  },
  icon: {
    marginTop: "auto",
    marginBottom: "auto",
    paddingRight: theme.spacing(1)
  }
}));

export function FullChipList(props) {
  const classes = useStyles()
  const { chipList, title, noHidden, children } = props
  if (noHidden || (chipList && chipList.length)) {
    return (
      <React.Fragment>
        <div className={classes.category}>
          <div className={classes.categoryTitle}>
            {children && <div className={classes.icon}>
              {children}
            </div>
            }
            <Typography
              variant="subtitle1">
              {title}
            </Typography>
          </div>
          <div className={classes.categoryChips}>
            <div className={classes.chips}>
              {chipList && chipList.length ? (
                chipList.map((value) => (
                  <Chip
                    size="small"
                    key={value}
                    label={value}
                    className={classes.chip} />
                ))
              )
                : null}
            </div>
          </div>
        </div >
        <Divider />
      </React.Fragment>
    )
  }
  return null
}

export function SliderList(props) {
  const classes = useStyles()
  const { value, min, max, marks, displayValue, step, title, children } = props
  const valueLabelFormat = (x) => {
    if (displayValue) {
      return x
    } else {
      return
    }
  }
  if (value) {
    return (
      <React.Fragment>
        <div className={classes.sliderCategory}>
          <div className={classes.categoryTitle}>
            <div className={classes.icon}>
              {children}
            </div>
            <Typography
              variant="subtitle1">
              {title}
            </Typography>
          </div>
          <div className={classes.slider}>
            <Slider
              value={value}
              valueLabelFormat={valueLabelFormat}
              valueLabelDisplay="on"
              aria-labelledby="discrete-slider-small-steps"
              step={step}
              marks={marks}
              min={min}
              max={max}
              track={false}
            />
          </div>
        </div >
        <Divider />
      </React.Fragment>
    )
  }
  return null
}
export function FlagList(props) {
  const classes = useStyles()
  const { value, title, children } = props
  const flagIcon = () => {
    if (value === false) {
      return <CloseIcon color="error" />
    } else if (value === true) {
      return <RadioButtonUncheckedIcon color="secondary" />
    } else {
      return <RemoveIcon />
    }
  }
  return (
    <React.Fragment>
      <div className={classes.category}>
        <div className={classes.categoryTitle}>
          <div className={classes.icon} >
            {children}
          </div>
          <Typography
            variant="subtitle1">
            {title}
          </Typography>
        </div>
        <div className={classes.categoryChips}>
          {flagIcon()}
        </div>
      </div >
      <Divider />
    </React.Fragment>
  )
}

export function SimpleList(props) {
  const classes = useStyles()
  const { value, title, noHidden } = props
  if (noHidden || value) {
    return (
      <React.Fragment>
        <div className={classes.simpleCategory}>
          <div className={classes.simpleCategoryTitle}>
            <Typography
              variant="subtitle1">
              {title}
            </Typography>
          </div>
          <div className={classes.simpleCategoryContent}>
            {value &&
              <NewLineTypography
                variant="body1">
                {value}
              </NewLineTypography>
            }
          </div>
        </div >
        <Divider />
      </React.Fragment >
    )
  }
  return null
}

export function SimpleFullList(props) {
  const classes = useStyles()
  const { value, title, noHidden } = props
  if (noHidden || value) {
    return (
      <React.Fragment>
        <div className={classes.simpleCategory}>
          <div className={classes.categoryTitle}>
            <Typography
              variant="subtitle1">
              {title}
            </Typography>
          </div>
          <div className={classes.categoryContent}>
            {value &&
              <NewLineTypography
                variant="body1">
                {value}
              </NewLineTypography>
            }
          </div>
        </div >
        <Divider />
      </React.Fragment >
    )
  }
  return null
}
