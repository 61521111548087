import { initialPost } from "./shopDetailReducerInitial";
export const initialState = {
  isLoading: true,
  initUser: false,
  user: null,
  userPublic: null,
  userDetail: null,
  account: {
    userDetail: {
      avgBudgetLine: null,
      occupation: null,
    },
    userPublic: {
      displayName: null,
      sex: null,
      birthYear: null,
      birthMonth: null,
      birthDay: null,
      pref: null,
      fabFashionGenre: [],
      fabThriftingGenre: [],
      introduction: null,
      profilePhotoName: null,
    },
  },
  accountMaster: {
    sex: null,
    avgBudgetLine: null,
    occupation: null,
  },
  isDetective: true,
  likeShop: [],
  likePosts: { ...initialPost },
  likePostLoading: true,
  myPosts: { ...initialPost },
  myPostLoading: true,
  notifyCount: 0,
  notification: {
    results: [
      {
        id: 0,
        defaultLink: "",
        avatarLink: "",
        avatarUrl: "",
        primaryText: "",
        secondaryText: "",
        read: true,
      },
    ],
  },
  notificationLoading: true,
  addNotificationLoading: false,
  latestNotificationLoading: false,
};

export default initialState;
