import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
const OnlyAuthenticatedUser = lazy(() => import('./index'))

export default function OnlyAuthenticatedRoute(props) {
  const { children, ...rest } = props
  return (
    <Route {...rest} >
      <React.Fragment>
        <OnlyAuthenticatedUser />
        {children}
      </React.Fragment>
    </Route>
  )
}

