import React from "react";

export default function Logo(props) {
  const { color, width, height } = props;
  return (
    <svg
      version="1.2"
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 361.481 144.089"
    >
      <g id="レイヤー_1">
        <polygon
          stroke={color}
          fill={color}
          strokeWidth="3.0626"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
				340.759,43.565 298.064,49.645 298.064,39.571 340.759,19.892 			"
        />

        <polygon
          stroke={color}
          fill={color}
          strokeWidth="3.0626"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
				340.759,74.595 298.064,83.897 298.064,63.167 340.759,61.581 			"
        />

        <polygon
          stroke={color}
          fill={color}
          strokeWidth="3.0626"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
				340.759,126.839 298.064,118.021 298.064,97.294 340.759,103.168 			"
        />
        <g>
          <polygon
            stroke={color}
            fill={color}
            strokeWidth="3.0626"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
					275.541,41.482 259.978,106.111 231.599,106.111 231.599,126.839 274.858,126.839 283.841,41.482 				"
          />
        </g>

        <polygon
          stroke={color}
          fill={color}
          strokeWidth="3.0626"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
				172.789,41.482 146.33,41.482 146.33,62.211 172.789,62.211 187.639,62.211 217.038,62.211 217.038,41.482 187.639,41.482 			"
        />

        <polygon
          stroke={color}
          fill={color}
          strokeWidth="3.0626"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
				187.639,77.618 217.038,77.618 217.038,98.345 187.639,98.345 172.789,98.345 146.33,98.345 146.33,77.618 172.789,77.618 			"
        />
        <g>
          <polygon
            stroke={color}
            fill={color}
            strokeWidth="3.0626"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
					22.285,18.092 22.285,38.827 47.425,38.827 38.604,126.839 47.574,126.839 68.153,18.092 				"
          />
        </g>
        <g>
          <polygon
            stroke={color}
            fill={color}
            strokeWidth="3.0626"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
					171.319,18.092 177.198,126.839 186.168,126.839 192.046,18.092 				"
          />
        </g>

        <rect
          x="233.534"
          y="42.184"
          stroke={color}
          fill={color}
          strokeWidth="3.0626"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          width="22.355"
          height="20.729"
        />

        <rect
          x="202.327"
          y="18.869"
          stroke={color}
          fill={color}
          strokeWidth="3.0626"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          width="6.949"
          height="13.778"
        />

        <rect
          x="215.415"
          y="18.869"
          stroke={color}
          fill={color}
          strokeWidth="3.0626"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          width="6.95"
          height="13.778"
        />
        <g>
          <path
            stroke={color}
            fill={color}
            strokeWidth="3.0626"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
					M86.14,110.047c-4.171,0-6.437-1.752-13.482-1.752c-6.596,0-10.456,2.952-10.456,11.101c0,5.663,5.355,7.443,8.096,7.443
					c1.031,0,5.022,0,9.601,0h6.242h13.85V41.482H86.14V110.047z"
          />
        </g>
        <g>
          <path
            stroke={color}
            fill={color}
            strokeWidth="3.0626"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
					M127.034,110.047c4.172,0,6.437-1.752,13.483-1.752c6.596,0,10.457,2.952,10.457,11.101c0,5.663-5.357,7.443-8.098,7.443
					c-1.03,0-5.021,0-9.599,0h-6.244h-13.85V41.482h13.85V110.047z"
          />
        </g>
      </g>
    </svg>
  );
}
