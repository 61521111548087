import { Dialog } from "@material-ui/core";
import React from "react";
import ToVerifyEmailDialogMain from "./main";

export default function ToVerifyEmailDialog(props) {
  const { isOpen, canClose, handleClose } = props;
  return (
    <div>
      <Dialog fullWidth open={isOpen}>
        <ToVerifyEmailDialogMain
          canClose={canClose}
          handleClose={handleClose}
        />
      </Dialog>
    </div>
  );
}
