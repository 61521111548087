import { useCallback } from 'react'
import { useSelector } from 'react-redux'

export default function useTransMaster() {
  const master = useSelector(state => state.master)
  const accountMaster = useSelector(state => state.account.accountMaster)
  const likeShopList = useSelector(state => state.account.likeShop)

  // カテゴリと番号から値を返す
  const transIdToNameFromMaster = useCallback(
    (id, itemName) => {
      if (master[itemName] && master[itemName].data && id) {
        const value = master[itemName].data.find(
          item => item.id === id
        )
        return value.name
      } else return
    }, [master]
  )

  const transIdToNameFromAccountMaster = useCallback(
    (id, itemName) => {
      if (accountMaster[itemName] && accountMaster[itemName].data && id) {
        const value = accountMaster[itemName].data.find(
          item => item.id === id
        )
        return value.name
      } else return
    }, [accountMaster]
  )

  //カテゴリと番号のリストから値を返す
  const transIdListToNameListFromMaster = useCallback((shopItem, itemName) => {
    let transShopItem = []
    if (shopItem && shopItem.length) {
      transShopItem = shopItem.map((id) => {
        return transIdToNameFromMaster(id, itemName)
      })
    }
    return transShopItem
  }, [transIdToNameFromMaster])

  const userLikeShop = useCallback((shopId) => {
    return likeShopList.includes(shopId)
  }, [likeShopList])

  //shopをmasterで変換して返す
  const transShopFromMaster = useCallback((shop) => {
    return {
      ...shop,
      pref: transIdToNameFromMaster(shop.pref, "pref"),
      area: transIdToNameFromMaster(shop.area, "area"),
      closedDay: transIdListToNameListFromMaster(shop.closedDay, "closedDay"),
      thriftingGenre: transIdListToNameListFromMaster(shop.thriftingGenre, "thriftingGenre"),
      fashionGenre: transIdListToNameListFromMaster(shop.fashionGenre, "fashionGenre"),
      sexCategory: transIdListToNameListFromMaster(shop.sexCategory, "sexCategory"),
      country: transIdListToNameListFromMaster(shop.country, "country"),
      countryToBuy: transIdListToNameListFromMaster(shop.countryToBuy, "countryToBuy"),
      like: userLikeShop(shop.shopId)
    }
  }, [transIdToNameFromMaster, transIdListToNameListFromMaster, userLikeShop])


  return {
    transIdToNameFromMaster,
    transIdToNameFromAccountMaster,
    transIdListToNameListFromMaster,
    transShopFromMaster
  }
}
