import axios from "../logics/services/axios";
import initialState from "./userReducerInitial";
const postUrl = process.env.REACT_APP_API_URL + "/api/post";
const followUrl = process.env.REACT_APP_API_URL + "/api/follow/";

const getUserProfile = async (id) => {
  const url = process.env.REACT_APP_API_URL + "/api/user/" + id;
  const response = await axios.get(url).catch((err) => err);
  return response;
};

const getPostList = async (params) => {
  const response = await axios
    .get(postUrl, {
      params: params,
    })
    .catch((err) => err);
  return response;
};

const getFollowList = async (params) => {
  const response = await axios
    .get(followUrl, {
      params: params,
    })
    .catch((err) => err);
  return response;
};

const getShopList = async (params) => {
  const baseUrl = process.env.REACT_APP_API_URL + "/api/shop/";
  const response = await axios
    .get(baseUrl, {
      params: params,
    })
    .catch((err) => err.response);
  return response;
};

// Reducers
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_PROFILE":
      return { ...state, userProfile: action.data };
    case "CLEAR_USER_PROFILE":
      return { ...state, userProfile: {} };
    case "SET_USER_PROFILE_LOADING":
      return { ...state, userProfileLoading: action.data };
    case "SET_LIKE_POST_LOADING":
      return { ...state, likePostLoading: action.data };
    case "SET_LIKE_SHOPLIST":
      return {
        ...state,
        likeShopList: action.data.results,
        likeShopListCount: action.data.count,
      };
    case "SET_LIKE_SHOPLIST_LOADING":
      return { ...state, isLikeShopListLoading: action.data };
    case "SET_LIKE_POSTS":
      return { ...state, likePosts: action.data };
    case "SET_MY_POST_LOADING":
      return { ...state, myPostLoading: action.data };
    case "SET_MY_POSTS":
      return { ...state, myPosts: action.data };
    case "CHANGE_FOLLOW":
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          youFollow: action.data,
        },
      };
    case "SET_FOLLOW_LIST":
      return { ...state, followList: action.data };
    case "ADD_FOLLOW_LIST":
      action.data.results = state.followList.results.concat(
        action.data.results
      );
      return { ...state, followList: action.data };
    case "SET_FOLLOWER_LIST":
      return { ...state, followerList: action.data };
    case "ADD_FOLLOWER_LIST":
      action.data.results = state.followerList.results.concat(
        action.data.results
      );
      return { ...state, followerList: action.data };
    case "SET_FOLLOW_LIST_LOADING":
      return { ...state, followListLoading: action.data };
    case "SET_ADD_FOLLOW_LIST_LOADING":
      return { ...state, addFollowListLoading: action.data };
    case "CLEAR_FOLLOW_LIST":
      return { ...state, followList: null };
    default:
      return state;
  }
};

export const setUserProfile = (id) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_USER_PROFILE_LOADING",
      data: true,
    });
    const userProfile = await getUserProfile(id);
    if (userProfile.status === 200) {
      dispatch({
        type: "SET_USER_PROFILE",
        data: userProfile.data,
      });
    }
    dispatch({
      type: "SET_USER_PROFILE_LOADING",
      data: false,
    });
  };
};

export const clearUserProfile = () => {
  return {
    type: "CLEAR_USER_PROFILE",
  };
};

export const setLikePost = (id, page, postType) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_LIKE_POST_LOADING",
      data: true,
    });
    const params = {
      only_like: id,
      page_size: 10,
    };
    if (postType) {
      params.postType = postType;
    }
    if (page) {
      params.page = page;
    }
    const postList = await getPostList(params);
    if (postList.status === 200) {
      dispatch({
        type: "SET_LIKE_POSTS",
        data: postList.data,
      });
    }
    dispatch({
      type: "SET_LIKE_POST_LOADING",
      data: false,
    });
  };
};
export const setMyPost = (id, page, postType) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_MY_POST_LOADING",
      data: true,
    });
    const params = {
      userPublicId: id,
      page_size: 10,
    };
    if (postType) {
      params.postType = postType;
    }
    if (page) {
      params.page = page;
    }
    const postList = await getPostList(params);
    if (postList.status === 200) {
      dispatch({
        type: "SET_MY_POSTS",
        data: postList.data,
      });
    }
    dispatch({
      type: "SET_MY_POST_LOADING",
      data: false,
    });
  };
};

export const setLikeShopList = (id, page) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_LIKE_SHOPLIST_LOADING",
      data: true,
    });
    const params = {
      only_like: id,
      order: "like",
      page_size: 20,
      page: page,
    };
    const shopList = await getShopList(params);
    if (shopList.status === 200) {
      dispatch({
        type: "SET_LIKE_SHOPLIST",
        data: shopList.data,
      });
    }
    dispatch({
      type: "SET_LIKE_SHOPLIST_LOADING",
      data: false,
    });
  };
};

const formatFollowData = (data, followType) => {
  const newData = Object.assign({}, data);
  if (followType === "follow") {
    newData.results = newData.results.map((item) => ({
      createdAt: item.createdAt,
      user: item.followAt,
    }));
  } else {
    newData.results = newData.results.map((item) => ({
      createdAt: item.createdAt,
      user: item.followBy,
    }));
  }
  return newData;
};

export const setFollowList = (id, type) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_FOLLOW_LIST_LOADING",
      data: true,
    });
    const params = {
      page_size: 10,
    };

    if (type === "follow") {
      params.follow_by = id;
    } else {
      params.follow_at = id;
    }
    const followList = await getFollowList(params);
    if (followList.status === 200) {
      const data = formatFollowData(followList.data, type);
      if (type === "follow") {
        dispatch({
          type: "SET_FOLLOW_LIST",
          data: data,
        });
      } else {
        dispatch({
          type: "SET_FOLLOWER_LIST",
          data: data,
        });
      }
    }
    dispatch({
      type: "SET_FOLLOW_LIST_LOADING",
      data: false,
    });
  };
};

export const addFollowList = (id, type, page) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_ADD_FOLLOW_LIST_LOADING",
      data: true,
    });
    const params = {
      page_size: 10,
      page: page,
    };

    if (type === "follow") {
      params.follow_by = id;
    } else {
      params.follow_at = id;
    }
    const followList = await getFollowList(params);
    if (followList.status === 200) {
      const data = formatFollowData(followList.data, type);
      if (type === "follow") {
        dispatch({
          type: "ADD_FOLLOW_LIST",
          data: data,
        });
      } else {
        dispatch({
          type: "ADD_FOLLOWER_LIST",
          data: data,
        });
      }
    }
    dispatch({
      type: "SET_ADD_FOLLOW_LIST_LOADING",
      data: false,
    });
  };
};

export const clearFollowList = () => {
  return {
    type: "CLEAR_FOLLOW_LIST",
  };
};
export const changeFollow = (followed) => {
  return {
    type: "CHANGE_FOLLOW",
    data: followed,
  };
};

export default userReducer;
