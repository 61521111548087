import React, { useEffect, useState } from "react";
import { darkTheme, lightTheme } from "../../static/theme.js";
export const useDarkTheme = () => {
  const darkSetting = localStorage.getItem("darkSetting");
  const [darkSettingState, setDarkSettingState] = useState(darkSetting);
  const theme = React.useMemo(() => {
    if (darkSettingState) {
      return darkSettingState === "dark" ? darkTheme : lightTheme;
    }
    return darkTheme;
  }, [darkSettingState]);

  useEffect(() => {
    localStorage.setItem("darkSetting", darkSettingState);
  }, [darkSettingState]);

  return { theme, darkSettingState, setDarkSettingState };
};
