import { IconButton, ListItemSecondaryAction, Switch } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import Brightness4 from "@material-ui/icons/Brightness4";
import CloseIcon from "@material-ui/icons/Close";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import useIsOpen from "../../../logics/utils/isOpen";
import { LinkWithState } from "../../../logics/utils/useRouterWithState";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function Hamburger(props) {
  const { darkSetting, setDarkSetting } = props;
  const classes = useStyles();
  const [open, handleOpen, handleClose] = useIsOpen(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (open) {
      handleOpen();
    } else {
      handleClose();
    }
  };

  const changeMode = () => {
    if (darkSetting) {
      const newSetting = darkSetting === "dark" ? "light" : "dark";
      setDarkSetting(newSetting);
    } else {
      setDarkSetting("light");
    }
  };

  const MenuList = () => (
    <React.Fragment>
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <IconButton size="medium">
          <CloseIcon size="large" />
        </IconButton>
        <Divider />
        <List>
          <ListItem
            button
            key="古着屋を探す"
            component={LinkWithState}
            to="/search"
          >
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary="古着屋を探す" />
          </ListItem>
          <ListItem
            button
            key="みんなの投稿を見る"
            component={LinkWithState}
            to="/postList"
          >
            <ListItemIcon>
              <DynamicFeedIcon />
            </ListItemIcon>
            <ListItemText primary="みんなの投稿を見る" />
          </ListItem>
          <ListItem
            button
            key="古着屋を登録する"
            component={LinkWithState}
            to="/shopRegist"
          >
            <ListItemIcon>
              <AddLocationIcon />
            </ListItemIcon>
            <ListItemText primary="古着屋を登録する" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem
            button
            key="フルギンミとは"
            component={LinkWithState}
            to="/about"
          >
            <ListItemText primary="フルギンミとは" />
          </ListItem>
          <ListItem
            button
            key="お知らせ"
            component={LinkWithState}
            to="/notice"
          >
            <ListItemText primary="お知らせ" />
          </ListItem>
          <ListItem
            button
            key="よくある質問"
            component={LinkWithState}
            to="/faq"
          >
            <ListItemText primary="よくある質問" />
          </ListItem>
          <ListItem
            button
            key="お問い合わせ"
            component={LinkWithState}
            to="/contact"
          >
            <ListItemText primary="お問い合わせ" />
          </ListItem>
          <ListItem
            button
            key="意見・感想を送る"
            component={LinkWithState}
            to="/feedback"
          >
            <ListItemText primary="意見・感想を送る" />
          </ListItem>
          <Divider />
        </List>
      </div>
      <List>
        <ListItem key="darkmode">
          <ListItemIcon>
            <Brightness4 />
          </ListItemIcon>
          <ListItemText primary="ダークモード" />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              color="primary"
              checked={darkSetting === "dark"}
              onChange={changeMode}
            ></Switch>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <IconButton size="small" onClick={handleOpen}>
        <MenuIcon style={{ color: "black" }} />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={handleClose}>
        <MenuList />
      </Drawer>
    </React.Fragment>
  );
}
