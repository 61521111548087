// import { StylesProvider } from '@material-ui/core/styles';
import NoSsr from "@material-ui/core/NoSsr";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import App from "./App";
import "./index.css";
import accountReducer from "./reducers/accountReducer";
import filterReducer from "./reducers/filterReducer";
import postListReducer from "./reducers/postListReducer";
import shopDetailReducer from "./reducers/shopDetailReducer";
import shopListReducer from "./reducers/shopListReducer";
import shopMasterReducer from "./reducers/shopMasterReducer";
import topPageReducer from "./reducers/topPageReducer";
import userReducer from "./reducers/userReducer";
import * as serviceWorker from "./serviceWorker";

const reducer = combineReducers({
  shopList: shopListReducer,
  filter: filterReducer,
  shopDetail: shopDetailReducer,
  master: shopMasterReducer,
  account: accountReducer,
  topPage: topPageReducer,
  user: userReducer,
  postList: postListReducer,
});

const applyMiddlewareMethod =
  // process.env.NODE_ENV === "production"
  false
    ? applyMiddleware
    : (middleware) => composeWithDevTools(applyMiddleware(middleware));

const store = createStore(reducer, applyMiddlewareMethod(thunk));

ReactDOM.render(
  <Provider store={store}>
    <NoSsr>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </NoSsr>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
