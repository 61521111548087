import { Avatar, Box, Button, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { usePhotoContext } from '../../../logics/photo/tmpPhotoReducer';
import useCropPhoto from '../../../logics/photo/useCropPhoto';

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(3)
    },
    imgGrid: {
        position: "relative"
    },
    defaultImg: {
        height: "auto",
        width: '100%',
        objectFit: "cover",
        marginBottom: theme.spacing(1)
    },
    img: {
        width: "100%",
        height: "auto",
        objectFit: "contain",
        border: `2px ${theme.palette.action.selected} solid`
    },
    editIcon: {
        position: "absolute",
        top: theme.spacing(7),
        right: theme.spacing(7),
        zIndex: "1000"
    },

}));

function RegistAccountPhoto(props) {
    const classes = useStyles()
    const { onClickBack, onClickNext, onClickSkip } = props
    const [state, dispatch] = usePhotoContext()
    const {
        handleOpen,
        deleteImage,
        onChangeImage,
        CropDialog,
    } = useCropPhoto(1 / 1, state, dispatch)


    return (
        <React.Fragment>
            <DialogContent dividers>
                <Box className={classes.content}>
                    <Grid container direction="row" justify="space-around" spacing={3}>
                        {
                            !Object.keys(state).length && (
                                <Grid item
                                    xs={12} sm={6} md={4}>
                                    <Avatar src={`${process.env.PUBLIC_URL}/photo_select_1_1.png`} className={classes.defaultImg} />
                                    <Button fullWidth component="label" variant="outlined">
                                        画像を追加
                                    <input
                                            type="file"
                                            accept="image/*"
                                            name="topImage"
                                            id="topImage"
                                            style={{ display: "none" }}
                                            onChange={onChangeImage}
                                        />
                                    </Button>
                                </Grid>
                            )
                        }
                        {
                            Object.keys(state).map(key =>
                                <Grid item
                                    xs={12} sm={6} md={4}
                                    className={classes.imgGrid} >
                                    <Button onClick={() => handleOpen(key)}>
                                        <Avatar src={state[key].cropedImage} className={classes.img} />
                                    </Button>
                                    <EditIcon className={classes.editIcon} />
                                    <Button fullWidth variant="outlined" onClick={() => deleteImage(key)}>
                                        削除
                </Button>
                                    {CropDialog(key)}
                                </Grid>
                            )
                        }
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Grid container justify="space-around" spacing={1} >
                    <Grid item xs={3} >
                        <Button
                            variant="contained"
                            onClick={onClickBack}
                            fullWidth
                            color="secondary">
                            戻る
                    </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={onClickSkip}
                            color="primary">
                            スキップ
                </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={onClickNext}
                            color="primary">
                            次へ
                </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </React.Fragment>

    )
}

export default RegistAccountPhoto
