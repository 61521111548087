import { Badge, IconButton } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import React from "react";
import { useSelector } from "react-redux";
import useNotifyCounter from "../../../logics/services/firebase/firestore/useNotifyCounter";
import { useHistoryPushWithState } from "../../../logics/utils/useRouterWithState";

export default function NotifyButton(props) {
  const fuser = useSelector((state) => state.account.firebaseUser);
  const { notifyCount } = useNotifyCounter();
  const { historyPush } = useHistoryPushWithState();
  const handleClick = () => {
    historyPush("/notification");
  };
  return (
    <React.Fragment>
      {fuser && (
        <IconButton
          color="inherit"
          disableElevation
          size="small"
          onClick={handleClick}
        >
          <Badge badgeContent={notifyCount} color="secondary">
            <NotificationsIcon size="small" />
          </Badge>
        </IconButton>
      )}
    </React.Fragment>
  );
}
