import axios from "../logics/services/axios";
import initialState from "./postListReducerInitial";

const postUrl = process.env.REACT_APP_API_URL + "/api/post";

const getPostList = async (params) => {
  const response = await axios
    .get(postUrl, {
      params: params,
    })
    .catch((err) => err);
  return response;
};

const POSTTYPE_ENUM = {
  review: "0",
  buyItem: "1",
  coord: "2",
};
// Reducers
const postListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_POST_LIST":
      return { ...state, posts: action.data };
    case "ADD_LATEST_POST_LIST":
      return {
        ...state,
        posts: {
          ...state.posts,
          results: action.data.concat(state.posts.results),
        },
      };
    case "ADD_POST_LIST":
      return {
        ...state,
        posts: {
          ...state.posts,
          next: action.data.next,
          previos: action.data.previos,
          results: state.posts.results.concat(action.data.results),
        },
      };
    case "SET_POST_LIST_LOADING":
      return { ...state, postLoading: action.data };
    case "SET_LATEST_POST_LIST_LOADING":
      return { ...state, latestPostLoading: action.data };
    case "SET_ADD_POST_LIST_LOADING":
      return { ...state, addPostLoading: action.data };
    case "SET_POST_FILTER":
      return { ...state, filter: action.data };
    case "RESET_POST_FILTER":
      return { ...state, filter: initialState.filter };
    default:
      return state;
  }
};

const createParams = (filter) => {
  const params = new URLSearchParams();
  params.append("page_size", 20);

  Object.keys(filter.postType).forEach((type) => {
    if (filter.postType[type]) {
      params.append("postType", POSTTYPE_ENUM[type]);
    }
  });
  if (filter.keyword !== "") {
    params.append("search", filter.keyword);
  }
  if (filter.only_follow) {
    params.append("only_follow", true);
  }
  return params;
};

export const setPostList = (filter) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_POST_LIST_LOADING",
      data: true,
    });
    const params = createParams(filter);
    const postList = await getPostList(params);
    if (postList.status === 200) {
      dispatch({
        type: "SET_POST_LIST",
        data: postList.data,
      });
    }
    dispatch({
      type: "SET_POST_LIST_LOADING",
      data: false,
    });
  };
};

export const addLatestPostList = (minId, filter) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_LATEST_POST_LIST_LOADING",
      data: true,
    });
    const params = createParams(filter);
    if (minId) {
      params.append("minId", minId);
    }
    params.append("minId", minId);
    params.set("page_size", 100);
    params.append("ordering", "id");

    const postList = await getPostList(params);
    if (postList.status === 200) {
      postList.data.results.reverse();
      dispatch({
        type: "ADD_LATEST_POST_LIST",
        data: postList.data.results,
      });
    }
    dispatch({
      type: "SET_LATEST_POST_LIST_LOADING",
      data: false,
    });
  };
};

export const addPostList = (maxId, filter) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_ADD_POST_LIST_LOADING",
      data: true,
    });
    const params = createParams(filter);
    params.append("maxId", maxId);

    const postList = await getPostList(params);
    if (postList.status === 200) {
      dispatch({
        type: "ADD_POST_LIST",
        data: postList.data,
      });
    }
    dispatch({
      type: "SET_ADD_POST_LIST_LOADING",
      data: false,
    });
  };
};

export const setFilter = (data) => {
  return {
    type: "SET_POST_FILTER",
    data: data,
  };
};

export const resetFilter = () => {
  return {
    type: "RESET_POST_FILTER",
  };
};

export default postListReducer;
