import { Box, Button, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    text: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: "100%"
    },
    textFieldStyle: {
        width: "100%",
        marginBottom: theme.spacing(1)
    },
    goTopButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }

}));

function RegistedAccountInfo(props) {
    const classes = useStyles()
    const { onClick, sentEmail } = props

    return (
        <DialogContent dividers>
            <Box>
                {sentEmail
                    ? <Box>
                        <Typography variant="h3" component="h2" className={classes.text}>
                            認証メールを送信しました。
                        </Typography>
                        <Typography variant="subtitle1" className={classes.text}>
                            登録いただきましたメールアドレスに認証メールを送信しました。
                            メールに記載されているURLをクリックして
                            メールアドレス登録手続きを完了してください。
                        </Typography>
                    </Box>
                    : <Box>
                        <Typography variant="h3" component="h2" className={classes.text}>
                            登録完了
                        </Typography>
                        <Typography variant="subtitle1" className={classes.text}>
                            登録ありがとうございました!
                            <br />
                            引き続きフルギンミをお楽しみください。
                        </Typography>
                    </Box>
                }
                <Button
                    className={classes.goTopButton}
                    variant="contained"
                    fullWidth
                    size="large"
                    onClick={onClick}
                    color="secondary">
                    閉じる
                </Button>
            </Box>
        </DialogContent>
    )
}

export default RegistedAccountInfo
