import { Button, ButtonGroup, Dialog, DialogTitle } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React, { useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Resizer from "react-image-file-resizer";
import usePrevState from "../../logics/utils/usePrevState";
import { useWindowDimensions } from "../../logics/utils/useWindowDimensions";

const WINDOW_IMG_SCALE = 0.9; // windowに対するimgのscale

export default function useCropPhoto(defaultAspect, state, dispatch) {
  const prevState = usePrevState(state);
  const { windowWidth, windowHeight } = useWindowDimensions();
  const handleOpen = (index) => {
    dispatch({ type: "OPEN", id: index });
  };

  const handleClose = (index) => {
    dispatch({ type: "CLOSE", id: index });
  };

  const onCropChange = (crop, index) => {
    dispatch({ type: "SET_TMP_CROP", id: index, crop: crop });
  };

  const handleSubmit = (index) => {
    dispatch({ type: "SET_CROP", id: index, crop: state[index].tmpCrop });
    dispatch({ type: "CLOSE", id: index });
  };

  const deleteImage = (index) => {
    dispatch({ type: "DELETE", id: index });
  };
  const setAspect = (aspect, index) => {
    dispatch({ type: "SET_ASPECT", id: index, aspect: aspect });
  };

  const onChangeImage = async (e) => {
    const resizeFile = (file) =>
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          1920,
          1920,
          "JPEG",
          100,
          0,
          (uri) => {
            resolve(uri);
          },
          "base64"
        );
      });

    let resizeImages = [];
    const files = Array.from(e.target.files);
    if (files.length) {
      resizeImages = await Promise.all(
        files.map(async (file) => {
          return await resizeFile(file);
        })
      );
      resizeImages.forEach((value, index) => {
        const id = Object.keys(state).length + index;
        dispatch({ type: "INIT", id: id });
        dispatch({ type: "SET_RAW", img: value, id: id });
        const imgObject = new Image();
        imgObject.src = value;
        imgObject.onload = async function () {
          await onLoad(imgObject, id);
        };
      });
    }
  };

  const setCropToCenter = (index, aspect, img) => {
    //中央にcropを合わせる
    const width =
      img.width / aspect < img.height
        ? 100
        : ((img.height * aspect) / img.width) * 100;
    const height =
      img.width / aspect > img.height
        ? 100
        : (img.width / aspect / img.height) * 100;
    const y = (100 - height) / 2;
    const x = (100 - width) / 2;
    const newCrop = {
      unit: "%",
      width,
      height,
      x,
      y,
      aspect: aspect,
    };

    dispatch({ type: "SET_CROP", id: index, crop: newCrop });
    dispatch({ type: "SET_TMP_CROP", id: index, crop: newCrop });
  };

  const onLoad = (img, index) => {
    // 画面サイズに合わせて、imageをresizeする
    const imgAspect = img.height / img.width;
    const windowAspect = windowHeight / windowWidth;
    if (windowAspect > imgAspect) {
      img.height = windowWidth * WINDOW_IMG_SCALE * imgAspect;
      img.width = windowWidth * WINDOW_IMG_SCALE;
    } else {
      img.height = windowHeight * WINDOW_IMG_SCALE;
      img.width = (windowHeight * WINDOW_IMG_SCALE) / imgAspect;
    }
    dispatch({ type: "SET_REF", img: img, id: index });

    if (!state[index] || !state[index].initialized) {
      setCropToCenter(index, defaultAspect, img);
      dispatch({ type: "INITIALIZE", id: index });
    } else {
      //現在のcropのtmpCropを合わせる
      dispatch({ type: "INIT_TMP_CROP", id: index });
    }
    return false;
  };

  const makeClientCrop = async (index) => {
    const imgState = state[index];
    if (
      imgState &&
      imgState.imageRef &&
      imgState.crop.width &&
      imgState.crop.height
    ) {
      const croppedImageUrl = await getCroppedImg(
        imgState.imageRef,
        imgState.crop,
        imgState.name
      );
      dispatch({ type: "SET_CROP_IMG", id: index, img: croppedImageUrl });
    }
  };

  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const unitIsPercent = crop.unit === "%";
    const scaleX = unitIsPercent
      ? image.naturalWidth * 0.01
      : image.naturalWidth / image.width;
    const scaleY = unitIsPercent
      ? image.naturalHeight * 0.01
      : image.naturalHeight / image.height;
    const cropScale =
      image.naturalWidth / image.width > image.naturalHeight / image.height
        ? image.naturalWidth / image.width
        : image.naturalHeight / image.height;
    const cropWidth = unitIsPercent
      ? crop.width * image.width * 0.01 * cropScale
      : crop.width * cropScale;
    const cropHeight = unitIsPercent
      ? crop.height * image.height * 0.01 * cropScale
      : crop.height * cropScale;
    canvas.width = cropWidth;
    canvas.height = cropHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      cropWidth,
      cropHeight
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        let fileUrl;
        window.URL.revokeObjectURL(fileUrl);
        fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      }, "image/jpeg");
    });
  }

  // cropに変更があった場合のみcropedImageを再生成
  useEffect(() => {
    if (Object.keys(state).length) {
      Object.keys(state).forEach((index) => {
        if (
          prevState &&
          prevState[index] &&
          state[index].crop !== prevState[index].crop
        ) {
          makeClientCrop(index);
        }
      });
    }
    //eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (Object.keys(state).length) {
      Object.keys(state).forEach((index) => {
        if (
          prevState &&
          prevState[index] &&
          state[index].aspect !== prevState[index].aspect
        ) {
          setCropToCenter(index, state[index].aspect, state[index].imageRef);
        }
      });
    }
    //eslint-disable-next-line
  }, [state]);

  const CropDialog = (index) => {
    const imgState = state[index];

    return (
      <Dialog
        fullScreen
        open={imgState.dialog}
        onClose={() => handleClose(index)}
      >
        <DialogTitle>
          <ButtonGroup fullWidth>
            <Button variant="contained" onClick={() => handleClose(index)}>
              キャンセル
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit(index)}
            >
              完了
            </Button>
          </ButtonGroup>
        </DialogTitle>
        <Box
          style={{ height: "100%", width: "100%" }}
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          {Object.keys(state).length && (
            <ReactCrop
              imageStyle={{
                objectFit: "contain",
                maxWidth: "100%",
                maxHeight: "100%",
                height: "auto",
                // position: "absolute"
              }}
              style={{
                objectFit: "contain",
                maxWidth: "100%",
                maxHeight: "100%",
                position: "relative",
              }}
              src={imgState.rawImage}
              crop={imgState.tmpCrop}
              locked
              ruleOfThirds
              onImageLoaded={(img) => onLoad(img, index)}
              onChange={(crop) => onCropChange(crop, index)}
            />
          )}
        </Box>
      </Dialog>
    );
  };
  return {
    handleOpen,
    deleteImage,
    onChangeImage,
    CropDialog,
    setAspect,
  };
}
