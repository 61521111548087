import { Box, Container, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from '../../components/molecules/helmet';
import useScrollTop from '../../logics/utils/useScrollTop';
import CategoryFooter from '../modules/categoryFooter';
import Footer from '../modules/footer';
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "60px",
  },
  content: {
    padding: theme.spacing(3),
    textAlign: "center"
  }
}));
const title = "ページが見つかりませんでした。 | フルギンミ"
const description = `
お探しのページは見つかりませんでした。移動・もしくは削除された可能性があります。
`

export default function NotFound() {
  useScrollTop()
  const classes = useStyles();
  return (
    <React.Fragment>
      <Helmet
        title={title}
        description={description}
      />
      <Container maxWidth="md" className={classes.container}>
        <Paper>
          <Box className={classes.content}>
            <Typography variant="h2" component="h1" >
              404 NOT FOUND
          </Typography>
            <br></br>
            <Typography variant="h4" component="h2" >
              お探しのページは見つかりませんでした。移動・もしくは削除された可能性があります。
          </Typography>
            <br></br>
            <Button
              variant="contained"
              component={Link}
              to="/"
              color="primary">
              トップページへ戻る
            </Button>
          </Box>
        </Paper>
      </Container>
      <CategoryFooter />
      <Footer />
    </React.Fragment >
  );
}