import { Button, CircularProgress } from '@material-ui/core'
import React from 'react'

const LoadingButton = (props) => {
    const { isLoading, children, ...others } = props
    return (
        < Button disabled={isLoading} {...others} >
            {
                isLoading
                    ? <CircularProgress size={16} />
                    : children
            }
        </Button >
    )
}
export default LoadingButton