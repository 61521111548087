import { useEffect, useState } from 'react';

export const useWindowDimensions = () => {

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            windowWidth: width,
            windowHeight: height
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        const onResize = () => {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);
    return windowDimensions;
}
