import React, { createContext, useContext, useReducer } from "react";
import { v4 as uuidv4 } from "uuid";

const initialStates = {};
const defaultState = {
  aspect: 1,
  rawImage: "",
  imageRef: "",
  cropedImage: "",
  crop: "",
  tmpCrop: "",
  name: "",
  initialized: false,
  dialog: false,
};
const photoReducer = (state, action) => {
  switch (action.type) {
    case "INIT":
      const initState = Object.assign({}, defaultState);
      initState.name = `${uuidv4()}.jpeg`;
      return { ...state, [action.id]: initState };
    case "SET_NAME":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          name: action.name,
        },
      };
    case "SET_CROP":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          crop: action.crop,
        },
      };
    case "SET_TMP_CROP":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          tmpCrop: action.crop,
        },
      };
    case "INIT_TMP_CROP":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          tmpCrop: Object.assign({}, state[action.id].crop),
        },
      };
    case "SET_RAW":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          rawImage: action.img,
        },
      };
    case "SET_REF":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          imageRef: action.img,
        },
      };
    case "SET_CROP_IMG":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          cropedImage: action.img,
        },
      };
    case "INITIALIZE":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          initialized: true,
        },
      };
    case "OPEN":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          dialog: true,
        },
      };
    case "CLOSE":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          dialog: false,
        },
      };
    case "DELETE":
      const newState = Object.assign({}, state);
      delete newState[action.id];
      const sortedState = {};
      Object.keys(newState).forEach((photoId, index) => {
        sortedState[index] = newState[photoId];
      });
      return sortedState;
    case "SET_ASPECT":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          aspect: action.aspect,
        },
      };
    default:
      return state;
  }
};

export const usePhotoReducer = () => {
  const [state, dispatch] = useReducer(photoReducer, initialStates);
  return [state, dispatch];
};

export const photoStateContext = createContext([]);

export const usePhotoContext = () => {
  return useContext(photoStateContext);
};

export const TmpPhotoProvider = ({ children, state, dispatch }) => {
  return (
    <photoStateContext.Provider value={[state, dispatch]}>
      {children}
    </photoStateContext.Provider>
  );
};
