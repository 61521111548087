import { Divider, IconButton } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/molecules/logoSvg";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    width: "100vw",
    backgroundColor: theme.palette.background.default,
  },
  copyLight: {
    width: "100%",
    marginBottom: theme.spacing(10),
    textAlign: "center",
  },
  sns: {
    marginTop: theme.spacing(5),
    marginLeft: "auto",
    marginRight: "auto",
    width: "90%",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    flexWrap: "wrap",
    justifyContent: "space-around",
    textAlign: "center",
  },
  iconWrapper: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    margin: "auto",
  },
  twitter: {
    backgroundColor: "#1DA1F2",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // borderRadius: "10px"
  },
  instagram: {
    backgroundColor: "#CF2E92",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // borderRadius: "10px"
  },
  linkList: {
    marginTop: theme.spacing(3),
    marginLeft: "auto",
    marginRight: "auto",
    width: "90%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  footerLink: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  logo: {
    margin: theme.spacing(2),
    textAlign: "center",
    align: "center",
  },
  logoSvg: {
    color: theme.palette.text.primary,
    scale: "50%",
  },
  title: {
    width: "100%",
    align: "center",
  },
  titleLink: {
    textDecoration: "none",
    align: "center",
    color: theme.palette.text.primary,
  },
}));

function FooterLink(props) {
  const { title, to } = props;
  const classes = useStyles();
  return (
    <div className={classes.footerLink}>
      <Typography variant="subtitle1" className={classes.title}>
        <Link to={to} className={classes.titleLink}>
          {title}
        </Link>
      </Typography>
    </div>
  );
}

export default function Footer(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <Divider />
      <div className={classes.linkList}>
        <FooterLink title="フルギンミとは" to="/about" />
        <FooterLink title="お問い合わせ" to="/contact" />
        <FooterLink title="意見・感想を送る" to="/feedback" />
        <FooterLink title="お知らせ" to="/notice" />
        <FooterLink title="よくある質問" to="/faq" />
        <FooterLink title="利用規約" to="/tos" />
        <FooterLink title="プライバシーポリシー" to="/privacypolicy" />
        <FooterLink title="推奨環境" to="/recenv" />
      </div>
      <div className={classes.sns}>
        <Typography variant="subtitle1">SNSをフォロー</Typography>
        <div className={classes.iconWrapper}>
          <div className={classes.twitter}>
            <IconButton
              conponent={Link}
              href="https://twitter.com/furuginme"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon size="large" />
            </IconButton>
          </div>
          <div className={classes.instagram}>
            <IconButton
              conponent={Link}
              href="https://www.instagram.com/furuginme/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon color="inherit" size="large" />
            </IconButton>
          </div>
        </div>
      </div>
      <div className={classes.logo}>
        <Logo color={theme.palette.text.primary} width="200px" height="100px" />
      </div>
      <div className={classes.copyLight}>
        <Typography variant="subtitle2">Copyright © FURUGINME 2021.</Typography>
      </div>
    </div>
  );
}
