import {
  getAnalytics,
  logEvent,
  setCurrentScreen,
  setUserId,
} from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setFirebaseUser } from "../../../reducers/accountReducer";
import firebaseApp from "./index.js";
export const useTracking = () => {
  let location = useLocation();
  const dispatch = useDispatch();
  const analytics = getAnalytics(firebaseApp);
  const auth = getAuth(firebaseApp);
  useEffect(() => {
    const query_params = location.search;
    const page_path = location.pathname;
    setCurrentScreen(analytics, page_path);
    logEvent(analytics, "screen_view", {
      query_params: query_params,
    });
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(setFirebaseUser(user));
        setUserId(analytics, user.uid);
      } else {
        dispatch(setFirebaseUser(null));
      }
    });
  }, [dispatch, auth, analytics]);

  return null;
};
