import { IconButton } from "@material-ui/core";
import ArrowBackIosRounded from "@material-ui/icons/ArrowBackIosRounded";
import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

const createTo = (to, location) => {
  if (typeof to === "string") {
    const toArray = to.split("?");
    const toObject = {
      pathname: toArray[0],
      state: { prev: true, from: location.pathname + location.search },
    };
    if (toArray[1]) {
      toObject.search = "?" + toArray[1];
    }
    return toObject;
  } else if (typeof to === "object") {
    if (to.state) {
      to.state["prev"] = true;
    } else {
      to["state"] = { prev: true, from: location.pathname + location.search };
    }
    return to;
  }
};

export const LinkWithState = React.forwardRef((props, ref) => {
  const { to, children, ...otherProps } = props;
  const location = useLocation();
  const toObject = createTo(to, location);
  return (
    <Link ref={ref} to={createTo(toObject)} {...otherProps}>
      {children}
    </Link>
  );
});

export function useHistoryPushWithState() {
  const history = useHistory();
  const historyPush = (to) => {
    const toObject = createTo(to, history.location);
    history.push(toObject);
  };
  return { historyPush };
}

export function useHistoryReplaceWithState() {
  const history = useHistory();
  const historyReplace = (to) => {
    history.replace(to, history.location.state);
  };
  return { historyReplace };
}

export function useGoBackOrTop() {
  const history = useHistory();
  const location = useLocation();
  const goBackOrTop = () => {
    const hasPrev = location.state?.prev;
    if (hasPrev) {
      history.goBack();
    } else {
      const toObject = createTo("/", location);
      history.push(toObject);
    }
  };
  return { goBackOrTop };
}

export function useLocationState() {
  const location = useLocation();
  return { from: location.state?.from, prev: location.state?.prev, location };
}

export function GoBackButton(props) {
  const { iconProps, ...otherProps } = props;
  const { goBackOrTop } = useGoBackOrTop();
  return (
    <IconButton onClick={goBackOrTop} {...otherProps}>
      <ArrowBackIosRounded {...iconProps} />
    </IconButton>
  );
}
