const initialPostPhotoResults = [
  {
    url: "",
    priorityId: 0,
    postId: 0,
    shopId: null,
    shopName: null,
  },
]
const initialPostResults = [
  {
    id: null,
    postPhoto: initialPostPhotoResults,
    userPublicId: {
      photoUrl: null,
      publicId: null,
      displayName: null,
    },
    content: null,
    postType: 0,
    createdAt: null,
    isPublic: null,
    shopId: null,
    shopName: null,
  },
]

export const initialState = {
  topPostPhoto: initialPostPhotoResults,
  topPost: initialPostResults,
  topPostLoading: true,
  topPostPhotoLoading: true,
}

export default initialState