import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Controller, useWatch } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginX: theme.spacing(1),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        // marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            border: '1px solid #d3d4d5',
        },
    },
};

function getStyles(id, value, theme) {
    return {
        fontWeight:
            value.indexOf(id) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium
    };
}

function MultiSelectWithChips(props) {
    const classes = useStyles()
    const { setValue, name, list, control, label } = props
    const [stateValue, setStateValue] = useState([]);
    const theme = useTheme();
    const handleChange = (event) => {
        setValue(name, event.target.value)
    }

    const value = useWatch({ control, name })

    useEffect(() => {
        setStateValue(value)
    }, [value])

    return (
        <FormControl className={classes.formControl} variant="outlined" fullWidth>
            <InputLabel id="filterName">{label}</InputLabel>
            <Controller
                control={control}
                name={name}
                multiple
                render={({ props }) => (
                    <Select
                        labelId={`${name}Label`}
                        id={name}
                        multiple
                        value={stateValue}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" style={{ minHeight: 40 }} />}
                        renderValue={(selected) => (
                            <div className={classes.chips}>
                                {selected.map((value) => {
                                    const item = list.find(item => item.id === value)
                                    return (
                                        <Chip size="small" key={value} label={item.name} className={classes.chip} />
                                    )
                                })}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {list.length && list.map((item) => (
                            <MenuItem key={item.id} value={item.id} style={getStyles(item.id, stateValue, theme)}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>)}
            />
        </FormControl>
    )
}

export default MultiSelectWithChips
