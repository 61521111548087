import axios, { fetchBaseUrl } from '../logics/services/axios'
import initialState from './shopMasterReducerInitial'

const baseUrl = fetchBaseUrl() + '/api/master/shop/'

const fetchData = async (url) => {
  const response = await axios.get(url).catch((err) => err)
  return response
}

// Reducers
const shopMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SHOP_MASTER':
      return action.data
    case 'SET_MASTER_LOADING':
      return { ...state, isLoading: action.data }
    default:
      return state
  }
}

export const setShopMaster = () => {
  return async dispatch => {
    dispatch(
      {
        type: 'SET_MASTER_LOADING',
        data: true
      }
    )
    const url = baseUrl + "all/"
    const response = await fetchData(url)
    if (response.status === 200) {
      dispatch(
        {
          type: 'SET_SHOP_MASTER',
          data: response.data
        })
    }
    dispatch(
      {
        type: 'SET_MASTER_LOADING',
        data: false
      }
    )
  }
}

export const setYearOfManufacture = () => {
  return async dispatch => {
    const url = baseUrl + "year_of_manufacture/"
    const rawData = await fetchData(url)
    let data = {}
    data.name = "年代"
    data.data = rawData
    dispatch(
      {
        type: 'SET_YEAR_OF_MANUFACTURE',
        data: data
      })
  }
}

export default shopMasterReducer