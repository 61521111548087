import axios, { fetchBaseUrl } from '../logics/services/axios'
import initialState, { initialPostDetail } from './shopDetailReducerInitial'

const baseUrl = fetchBaseUrl() + '/api/shop/'
const postPhotoUrl = process.env.REACT_APP_API_URL + '/api/post_photo'
const postUrl = process.env.REACT_APP_API_URL + '/api/post'

const getShopDetail = async (shopId) => {
  const url = baseUrl + shopId + "/"
  const response = await axios.get(url).catch((err) => err)
  return response
}

const getShopList = async (params) => {
  const response = await axios.get(baseUrl,
    {
      params: params
    }).catch((err) => err)
  return response
}

const getPostPhotoList = async (params) => {
  const response = await axios.get(postPhotoUrl,
    {
      params: params
    }).catch((err) => err)
  return response
}

const getPostList = async (params) => {
  const response = await axios.get(postUrl,
    {
      params: params
    }).catch((err) => err)
  return response
}

const getPostDetail = async (postId) => {
  const url = process.env.REACT_APP_API_URL + `/api/post/${postId}`
  const response = await axios.get(url)
    .catch((err) => err)
  return response
}

const getPostComment = async (params) => {
  const url = process.env.REACT_APP_API_URL + `/api/post_comment`
  const response = await axios.get(url,
    {
      params: params
    })
    .catch((err) => err)
  return response
}

// Reducers
const shopDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_SHOP':
      return { ...state, shopDetail: action.data }
    case 'INIT_TRANS_SHOP':
      return { ...state, transShopDetail: action.data }
    case 'SET_SHOP_DETAIL_LOADING':
      return { ...state, isLoading: action.data }
    case 'CLEAR_SHOP':
      return initialState
    case 'SET_POST_PHOTO':
      return { ...state, postPhoto: action.data }
    case 'SET_POST_PHOTO_LOADING':
      return { ...state, postPhotoLoading: action.data }
    case 'SET_TOP_POST_PHOTO':
      return { ...state, topPostPhoto: action.data }
    case 'SET_TOP_POST_PHOTO_LOADING':
      return { ...state, topPostPhotoLoading: action.data }
    case 'SET_POST':
      return { ...state, post: action.data }
    case 'SET_POST_DETAIL':
      return { ...state, postDetail: action.data }
    case 'CLEAR_POST_DETAIL':
      return {
        ...state,
        postDetail: { ...initialPostDetail },
        postDetailLoading: true,
        postComment: { results: [], count: 0 },
        postCommentLoading: true
      }
    case 'SET_POST_COMMENT':
      return { ...state, postComment: action.data }
    case 'ADD_POST_COMMENT':
      return {
        ...state,
        postComment: {
          ...state.postComment,
          results: [...state.postComment.results, action.data],
        },
        postDetail: {
          ...state.postDetail,
          commentCount: parseInt(state.postDetail.commentCount) + 1
        }
      }
    case 'SET_POST_LOADING':
      return { ...state, postLoading: action.data }
    case 'SET_POST_COMMENT_LOADING':
      return { ...state, postCommentLoading: action.data }
    case 'SET_POST_DETAIL_LOADING':
      return { ...state, postDetailLoading: action.data }
    case 'SET_TOP_POST':
      return { ...state, topPost: action.data }
    case 'SET_TOP_POST_LOADING':
      return { ...state, topPostLoading: action.data }
    case 'SET_NEAR_BY_SHOPLIST':
      return { ...state, nearbyShopList: action.data }
    case 'SET_NEAR_BY_SHOPLIST_LOADING':
      return { ...state, isNearByShopListLoading: action.data }
    case 'NOT_FOUND':
      return { ...state, notFound: action.data }
    case 'POST_DETAIL_NOT_FOUND':
      return { ...state, postDetailNotFound: action.data }
    default:
      return state
  }
}

export const setShopDetail = (shopId) => {
  return async dispatch => {
    dispatch(
      {
        type: 'SET_SHOP_DETAIL_LOADING',
        data: true
      }
    )
    const shopDetail = await getShopDetail(shopId)
    if (shopDetail.status === 200) {
      dispatch(
        {
          type: 'INIT_SHOP',
          data: shopDetail.data
        })
    } else {
      dispatch(
        {
          type: 'NOT_FOUND',
          data: true
        })
    }

    dispatch(
      {
        type: 'SET_SHOP_DETAIL_LOADING',
        data: false
      })
  }
}

export const setTopPostPhoto = (shopId) => {
  return async dispatch => {
    dispatch(
      {
        type: 'SET_TOP_POST_PHOTO_LOADING',
        data: true
      }
    )
    const params = {
      shopId: shopId,
      page_size: 9,
    }
    const postPhotoList = await getPostPhotoList(params)
    if (postPhotoList.status === 200) {
      dispatch(
        {
          type: 'SET_TOP_POST_PHOTO',
          data: postPhotoList.data.results
        })
    }
    dispatch(
      {
        type: 'SET_TOP_POST_PHOTO_LOADING',
        data: false
      }
    )
  }
}

export const setTopPost = (shopId) => {
  return async dispatch => {
    dispatch(
      {
        type: 'SET_TOP_POST_LOADING',
        data: true
      }
    )
    const params = {
      shopId: shopId,
      page_size: 3,
    }
    const postList = await getPostList(params)
    if (postList.status === 200) {
      dispatch(
        {
          type: 'SET_TOP_POST',
          data: postList.data.results
        })
    }
    dispatch(
      {
        type: 'SET_TOP_POST_LOADING',
        data: false
      }
    )
  }
}

export const setPostPhoto = (shopId, page, postType) => {
  return async dispatch => {
    dispatch(
      {
        type: 'SET_POST_PHOTO_LOADING',
        data: true
      }
    )
    const params = {
      shopId: shopId,
      page_size: 21,
      page: page
    }
    if (postType) {
      params.postType = postType
    }
    if (page) {
      params.page = page
    }
    const postPhotoList = await getPostPhotoList(params)
    if (postPhotoList.status === 200) {
      dispatch(
        {
          type: 'SET_POST_PHOTO',
          data: postPhotoList.data
        })
    }
    dispatch(
      {
        type: 'SET_POST_PHOTO_LOADING',
        data: false
      }
    )
  }
}

export const setPost = (shopId, page, postType) => {
  return async dispatch => {
    dispatch(
      {
        type: 'SET_POST_LOADING',
        data: true
      }
    )
    const params = {
      shopId: shopId,
      page_size: 10,
      page: page
    }
    if (postType) {
      params.postType = postType
    }
    if (page) {
      params.page = page
    }
    const postList = await getPostList(params)
    if (postList.status === 200) {
      dispatch(
        {
          type: 'SET_POST',
          data: postList.data
        })
    }
    dispatch(
      {
        type: 'SET_POST_LOADING',
        data: false
      }
    )
  }
}

const setPostDetailFunc = async (postId, dispatch) => {
  dispatch(
    {
      type: 'SET_POST_DETAIL_LOADING',
      data: true
    }
  )

  const postDetail = await getPostDetail(postId)
  if (postDetail.status === 200) {
    dispatch(
      {
        type: 'SET_POST_DETAIL',
        data: postDetail.data
      })
  } else {
    dispatch(
      {
        type: 'POST_DETAIL_NOT_FOUND',
        data: true
      })
  }
  dispatch(
    {
      type: 'SET_POST_DETAIL_LOADING',
      data: false
    }
  )
}

const setPostCommentFunc = async (postId, dispatch) => {
  dispatch(
    {
      type: 'SET_POST_COMMENT_LOADING',
      data: true
    }
  )

  const commentParams = { post: postId, limit: 3 }
  const postComment = await getPostComment(commentParams)
  if (postComment.status === 200) {
    postComment.data.results.reverse()

    dispatch(
      {
        type: 'SET_POST_COMMENT',
        data: postComment.data
      })

  }
  dispatch(
    {
      type: 'SET_POST_COMMENT_LOADING',
      data: false
    }
  )
}

export const setPostDetail = (postId) => {
  return async dispatch => {
    await Promise.all([setPostDetailFunc(postId, dispatch), setPostCommentFunc(postId, dispatch)])
  }
}

export const setPostComment = (postId) => {
  return async dispatch => {
    dispatch(
      {
        type: 'SET_POST_COMMENT_LOADING',
        data: true
      }
    )
    const commentParams = { post: postId }
    const postComment = await getPostComment(commentParams)
    if (postComment.status === 200) {
      postComment.data.reverse()
      const data = {
        count: 0,
        results: postComment.data
      }
      dispatch(
        {
          type: 'SET_POST_COMMENT',
          data: data
        })
    }
    dispatch(
      {
        type: 'SET_POST_COMMENT_LOADING',
        data: false
      }
    )
  }
}

export const addPostComment = (data) => {
  return {
    type: 'ADD_POST_COMMENT',
    data: data
  }
}
export const setNearbyShopList = (lng, lat) => {
  return async dispatch => {
    dispatch(
      {
        type: 'SET_NEAR_BY_SHOPLIST_LOADING',
        data: true
      }
    )
    const params = {
      point: lng.toString() + "," + lat.toString(),
      order: "point",
      dist: 30000,
      page_size: 8,
    }
    const shopList = await getShopList(params)
    if (shopList.status === 200) {
      await shopList.data.results.shift() //近い店舗を検索しているが同一店舗が最初に出るのでshift
      dispatch(
        {
          type: 'SET_NEAR_BY_SHOPLIST',
          data: shopList.data.results
        })
    }
    dispatch(
      {
        type: 'SET_NEAR_BY_SHOPLIST_LOADING',
        data: false
      }
    )
  }
}

export const setTransShopDetail = (shop) => {
  return {
    type: 'INIT_TRANS_SHOP',
    data: shop
  }
}

export const clearShopDetail = () => {
  return {
    type: 'CLEAR_SHOP',
  }
}

export const clearPostDetail = () => {
  return {
    type: 'CLEAR_POST_DETAIL',
  }
}

export default shopDetailReducer