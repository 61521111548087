import axios, { fetchBaseUrl } from "../logics/services/axios";
import logout from "../logics/services/firebase/logout";
import initialState from "./accountReducerInitial";
const baseUrl = fetchBaseUrl() + "/api/";

const fetchData = async (url) => {
  const response = await axios.get(url).catch((err) => err.response);
  return response;
};

const fetchNotification = async (params) => {
  const url = process.env.REACT_APP_API_URL + "/api/notify";
  const response = await axios
    .get(url, {
      params: params,
    })
    .catch((err) => err);
  return response;
};

// Reducers
const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FIREBASE_USER":
      return { ...state, firebaseUser: action.data };
    case "LOGOUT":
      return {
        ...state,
        account: initialState.account,
        isDetective: true,
      };
    case "SET_ACCOUNT_MASTER":
      return { ...state, accountMaster: action.data };
    case "SET_ACCOUNT":
      return { ...state, account: action.data };
    case "SET_LIKE_SHOP":
      return { ...state, likeShop: action.data };
    case "CLEAR_LIKE_SHOP":
      return { ...state, likeShop: [] };
    case "DEL_LIKE_SHOP":
      const newDelLikeList = state.likeShop.filter((n) => n !== action.data);
      return { ...state, likeShop: newDelLikeList };
    case "ADD_LIKE_SHOP":
      const newAddLikeList = Object.assign([], state.likeShop);
      newAddLikeList.push(action.data);
      return { ...state, likeShop: newAddLikeList };
    case "INIT_USER":
      return { ...state, initUser: action.data };
    case "SET_NOT_DETECTIVE":
      return { ...state, isDetective: false };
    case "SET_DETECTIVE":
      return { ...state, isDetective: true };
    case "SET_USER_LOADING":
      return { ...state, isLoading: action.data };
    case "INIT_NOTIFY_COUNT":
      return { ...state, notifyCount: 0 };
    case "SET_NOTIFY_COUNT":
      return { ...state, notifyCount: action.data };
    case "SET_NOTIFICATION":
      return { ...state, notification: action.data };
    case "ADD_LATEST_NOTIFICATION":
      return {
        ...state,
        notification: {
          ...state.notification,
          results: action.data.concat(state.notification.results),
        },
      };
    case "ADD_NOTIFICATION":
      return {
        ...state,
        notification: {
          ...state.notification,
          next: action.data.next,
          previos: action.data.previos,
          results: state.notification.results.concat(action.data.results),
        },
      };
    case "SET_NOTIFICATION_LOADING":
      return { ...state, notificationLoading: action.data };
    case "SET_LATEST_NOTIFICATION_LOADING":
      return { ...state, latestNotificationLoading: action.data };
    case "SET_ADD_NOTIFICATION_LOADING":
      return { ...state, addNotificationLoading: action.data };
    default:
      return state;
  }
};

const setAccountFunc = async (dispatch) => {
  const url = baseUrl + "account/";
  const response = await fetchData(url);
  if (response.status === 200) {
    dispatch({
      type: "SET_ACCOUNT",
      data: response.data,
    });

    if (!response.data || !response.data.userPublic) {
      dispatch({
        type: "SET_NOT_DETECTIVE",
      });
    } else {
      dispatch({
        type: "SET_DETECTIVE",
      });
    }
  } else {
    dispatch({
      type: "SET_NOT_DETECTIVE",
    });
  }
};
const setLikeShopFunc = async (dispatch) => {
  const url = baseUrl + "like/";
  const response = await fetchData(url);
  if (response.status === 200) {
    const data = response.data.map((item) => item.shopId);
    dispatch({
      type: "SET_LIKE_SHOP",
      data: data,
    });
  }
};

export const setFirebaseUser = (user) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_USER_LOADING",
      data: true,
    });
    dispatch({
      type: "SET_FIREBASE_USER",
      data: user,
    });
    if (user) {
      await Promise.all([setAccountFunc(dispatch), setLikeShopFunc(dispatch)]);
    } else {
      dispatch({
        type: "CLEAR_LIKE_SHOP",
      });
    }

    dispatch({
      type: "SET_USER_LOADING",
      data: false,
    });

    dispatch({
      type: "INIT_USER",
      data: true,
    });
  };
};
export const setLogout = () => {
  logout();
  return {
    type: "LOGOUT",
  };
};
export const setUserLoading = (isLoading) => {
  return {
    type: "SET_USER_LOADING",
    data: isLoading,
  };
};

export const delLikeShop = (shopId) => {
  return {
    type: "DEL_LIKE_SHOP",
    data: shopId,
  };
};

export const addLikeShop = (shopId) => {
  return {
    type: "ADD_LIKE_SHOP",
    data: shopId,
  };
};

//account masterを読んでいる間にloadingいれるとちかちかするので覗く
export const setAccountMaster = () => {
  return async (dispatch) => {
    const url = baseUrl + "master/users/all/";
    const response = await fetchData(url);
    if (response.status === 200) {
      dispatch({
        type: "SET_ACCOUNT_MASTER",
        data: response.data,
      });
    }
  };
};

export const setAccount = () => {
  return async (dispatch) => {
    dispatch({
      type: "SET_USER_LOADING",
      data: true,
    });
    await setAccountFunc(dispatch);
    dispatch({
      type: "SET_USER_LOADING",
      data: false,
    });
  };
};

export const setLikeShop = () => {
  return async (dispatch) => {
    dispatch({
      type: "SET_USER_LOADING",
      data: true,
    });
    await setLikeShopFunc(dispatch);
    dispatch({
      type: "SET_USER_LOADING",
      data: false,
    });
  };
};

export const initNotifyCount = () => {
  return {
    type: "INIT_NOTIFY_COUNT",
  };
};

export const setNotifyCount = (count) => {
  return {
    type: "SET_NOTIFY_COUNT",
    data: count,
  };
};

const createNotificationParams = (filter) => {
  const params = new URLSearchParams();
  params.append("page_size", 20);
  return params;
};

const formatNotification = (data) => {
  const actionTypeData = {};
  switch (data.actionType) {
    case "follow":
      actionTypeData.defaultLink = `/user/${data.actionUserPublic?.publicId}`;
      actionTypeData.primaryText = `${data.actionUserPublic?.displayName}さんがフォローしました`;
      actionTypeData.secondaryText = "";
      break;
    case "post_comment":
      actionTypeData.defaultLink = data.postSlug;
      actionTypeData.primaryText = `${data.actionUserPublic?.displayName}さんが投稿にコメントしました`;
      actionTypeData.secondaryText = `${data.comment}`;
      break;
    case "post_like":
      actionTypeData.defaultLink = data.postSlug;
      actionTypeData.primaryText = `${data.actionUserPublic?.displayName}さんが投稿にいいねしました`;
      actionTypeData.secondaryText = `${data.postDescription}`;
      break;
    default:
      break;
  }

  const formattedData = {
    id: data.id,
    defaultLink: actionTypeData.defaultLink,
    avatarLink: `/user/${data.actionUserPublic?.publicId}`,
    avatarUrl: data.actionUserPublic?.photoUrl,
    primaryText: actionTypeData.primaryText,
    secondaryText: actionTypeData.secondaryText,
    read: data.read,
  };

  return formattedData;
};
export const setNotification = () => {
  return async (dispatch) => {
    dispatch({
      type: "SET_NOTIFICATION_LOADING",
      data: true,
    });
    const params = createNotificationParams();
    const notification = await fetchNotification(params);
    if (notification.status === 200) {
      notification.data.results = notification.data.results.map((item) =>
        formatNotification(item)
      );
      dispatch({
        type: "SET_NOTIFICATION",
        data: notification.data,
      });
    }
    dispatch({
      type: "SET_NOTIFICATION_LOADING",
      data: false,
    });
  };
};

export const addLatestNotification = (minId, filter) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_LATEST_NOTIFICATION_LOADING",
      data: true,
    });
    const params = createNotificationParams();
    if (minId) {
      params.append("minId", minId);
    }
    params.append("minId", minId);
    params.set("page_size", 100);
    params.append("ordering", "id");

    const notification = await fetchNotification(params);
    if (notification.status === 200) {
      const data = notification.data;
      data.results.reverse(); //必要? 上のorderingで対応できない?
      data.results = data.results.map((item) => formatNotification(item));
      dispatch({
        type: "ADD_LATEST_NOTIFICATION",
        data: data.results,
      });
    }
    dispatch({
      type: "SET_LATEST_NOTIFICATION_LOADING",
      data: false,
    });
  };
};

// 無限loadingで追加で読み込む
export const addNotification = (maxId) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_ADD_NOTIFICATION_LOADING",
      data: true,
    });
    const params = createNotificationParams();
    params.append("maxId", maxId);

    const notification = await fetchNotification(params);
    if (notification.status === 200) {
      notification.data.results = notification.data.results.map((item) =>
        formatNotification(item)
      );
      dispatch({
        type: "ADD_NOTIFICATION",
        data: notification.data,
      });
    }
    dispatch({
      type: "SET_ADD_NOTIFICATION_LOADING",
      data: false,
    });
  };
};

export default accountReducer;
