import axios from "../logics/services/axios";
import initialState from "./topPageReducerInitial";

const postPhotoUrl = process.env.REACT_APP_API_URL + "/api/post_photo";
const postUrl = process.env.REACT_APP_API_URL + "/api/post";

const getPostPhotoList = async (params) => {
  const response = await axios
    .get(postPhotoUrl, {
      params: params,
    })
    .catch((err) => err);
  return response;
};

const getPostList = async (params) => {
  const response = await axios
    .get(postUrl, {
      params: params,
    })
    .catch((err) => err);
  return response;
};

// Reducers
const shopDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TOP_PAGE_POST_PHOTO":
      return { ...state, topPostPhoto: action.data };
    case "SET_TOP_PAGE_POST_PHOTO_LOADING":
      return { ...state, topPostPhotoLoading: action.data };
    case "SET_TOP_PAGE_POST":
      return { ...state, topPost: action.data };
    case "SET_TOP_PAGE_POST_LOADING":
      return { ...state, topPostLoading: action.data };
    default:
      return state;
  }
};

export const setTopPostPhoto = () => {
  return async (dispatch) => {
    dispatch({
      type: "SET_TOP_PAGE_POST_PHOTO_LOADING",
      data: true,
    });
    const params = {
      page_size: 9,
    };
    const postPhotoList = await getPostPhotoList(params);
    if (postPhotoList.status === 200) {
      dispatch({
        type: "SET_TOP_PAGE_POST_PHOTO",
        data: postPhotoList.data.results,
      });
    }
    dispatch({
      type: "SET_TOP_PAGE_POST_PHOTO_LOADING",
      data: false,
    });
  };
};

export const setTopPost = () => {
  return async (dispatch) => {
    dispatch({
      type: "SET_TOP_PAGE_POST_LOADING",
      data: true,
    });
    const params = {
      page_size: 3,
    };
    const postList = await getPostList(params);
    if (postList.status === 200) {
      dispatch({
        type: "SET_TOP_PAGE_POST",
        data: postList.data.results,
      });
    }
    dispatch({
      type: "SET_TOP_PAGE_POST_LOADING",
      data: false,
    });
  };
};

export default shopDetailReducer;
