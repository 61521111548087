const initialPostPhotoResults = [
  {
    url: "",
    priorityId: 0,
    postId: 0,
    shopId: "",
    shopName: "",
  },
];
const initialPostResults = [
  {
    id: null,
    postPhoto: initialPostPhotoResults,
    userPublicId: {
      photoUrl: null,
      publicId: null,
      displayName: null,
    },
    content: null,
    postType: 0,
    createdAt: null,
    shopId: null,
    shopName: null,
  },
];

export const initialPostDetail = {
  id: null,
  postPhoto: initialPostPhotoResults,
  userPublicId: {
    photoUrl: null,
    publicId: null,
    displayName: null,
  },
  content: null,
  postType: 0,
  createdAt: null,
  shopId: null,
  shopName: null,
};

export const initialPost = {
  results: initialPostResults,
  nextUrl: "",
  count: 0,
  page: 1,
};

export const initialState = {
  posts: { ...initialPost },
  postLoading: true,
  addPostLoading: false,
  isLoading: true,
  filter: {
    keyword: "",
    postType: {
      buyItem: false,
      review: false,
      coord: false,
    },
  },
};

export default initialState;
