import React from 'react'
import ReactHelmet from 'react-helmet'
import { defaultDescription, defaultTitle } from "../../static/metaText"
export default function Helmet(props) {
    const { title, description, canonical } = props
    const newTitle = title ? title : defaultTitle
    const newDescription = description ? description : defaultDescription

    return (
        <ReactHelmet>
            <title>
                {newTitle}
            </title>

            <meta
                name="description"
                content={newDescription}
            />
            <meta
                name="og:title"
                content={newTitle}
            />
            <meta
                name="og:description"
                content={newDescription}
            />
            {canonical && <link rel="canonical" href={canonical} />}
        </ReactHelmet>
    )
}