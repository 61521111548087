import axios from '../logics/services/axios/index'
import initialState from './shopListReducerInitial'

const getAll = async (params) => {
  const baseUrl = process.env.REACT_APP_API_URL + "/api/shop/"
  const response = await axios.get(baseUrl,
    {
      params: params
    }).catch((err) => err.response)
  return response
}


const shopListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_SHOPLIST':
      action.data.results.forEach((value, index) => {
        value.listId = index
      })
      return {
        ...state,
        shopList: action.data.results,
        nextUrl: action.data.next,
        count: action.data.count,
        page: action.data.page,
        selectedMarkerId: 0
      }
    case 'ADD_SHOPLIST':
      action.data.results.forEach((value, index) => {
        value.listId = index + state.shopList.length
      })
      return {
        ...state,
        shopList: state.shopList.concat(action.data.results),
        nextUrl: action.data.next,
        count: action.data.count,
      }
    case 'INIT_NEW_SHOPLIST':
      return { ...state, newShopList: action.data }
    case 'SET_NEW_SHOPLIST_LOADING':
      return { ...state, isNewShopListLoading: action.data }
    case 'SET_SELECTED_MARKER':
      return { ...state, selectedMarkerId: action.data }
    case 'SET_GMAP_CENTER_LATLNG':
      return { ...state, gmapCenterLatLng: action.data }
    case 'SET_GMAP_ZOOM':
      return { ...state, gmapZoom: action.data }
    case 'SET_GMAP_BOUNDS':
      return { ...state, gmapBounds: action.data }
    case 'SET_SHOP_LIST_LOADING':
      return { ...state, isLoading: action.data }
    case 'SET_PAGE':
      return { ...state, page: action.data }
    case 'ADD_PAGE':
      return { ...state, page: state.nextUrl || state.page }
    case 'SET_VIEW_PORT':
      return { ...state, viewport: action.data }
    case 'NOT_FOUND':
      return { ...state, notFound: action.data }
    default:
      return state
  }
}

const addPageParams = (data, page) => {
  data.page = parseInt(page) || 1
  return data
}
// ここでloadingを設定するべきか微妙な気がするがまあ....
export const setShopList = (params) => {
  return async dispatch => {
    dispatch(
      {
        type: 'SET_SHOP_LIST_LOADING',
        data: true
      }
    )
    const shopList = await getAll(params)
    if (shopList.status === 200) {
      const data = addPageParams(shopList.data, params.get("page"))
      dispatch(
        {
          type: 'INIT_SHOPLIST',
          data: data
        })
    }
    dispatch(
      {
        type: 'SET_SHOP_LIST_LOADING',
        data: false
      }
    )
  }
}

export const setNewShopList = () => {
  return async dispatch => {
    dispatch(
      {
        type: 'SET_NEW_SHOPLIST_LOADING',
        data: true
      }
    )
    const params = {
      order: "new",
      page_size: 8,
    }
    const shopList = await getAll(params)
    if (shopList.status === 200) {
      dispatch(
        {
          type: 'INIT_NEW_SHOPLIST',
          data: shopList.data.results
        })
    }
    dispatch(
      {
        type: 'SET_NEW_SHOPLIST_LOADING',
        data: false
      }
    )
  }
}

export const addShopList = (params) => {
  return async dispatch => {
    const shopList = await getAll(params)
    if (shopList.status === 200) {
      dispatch(
        {
          type: 'ADD_SHOPLIST',
          data: shopList.data
        })
    }
  }
}

export const setSelectedMarker = (shopId) => {
  return {
    type: 'SET_SELECTED_MARKER',
    data: shopId
  }
}

export const setCenter = (data) => {
  return {
    type: 'SET_GMAP_CENTER_LATLNG',
    data: data
  }
}

export const setZoom = (zoom) => {
  return {
    type: 'SET_GMAP_ZOOM',
    data: zoom

  }
}

export const setGmapBounds = (bounds) => {
  return {
    type: 'SET_GMAP_BOUNDS',
    data: bounds
  }
}

export const setPage = (pageId) => {
  return {
    type: 'SET_PAGE',
    data: pageId
  }
}

export const addPage = () => {
  return {
    type: 'ADD_PAGE'
  }
}

export const setViewport = (data) => {
  return {
    type: 'SET_VIEW_PORT',
    data: data
  }
}

export default shopListReducer