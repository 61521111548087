import useInitFilters from '../../logics/services/filters/init.js';
import { useTracking } from '../../logics/services/firebase/tracking.js';
import useMaster from '../../logics/services/master/index.js';

export default function App() {
  useMaster()
  useTracking()
  useInitFilters()

  return (null
  );
}

