import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  initNotifyCount,
  setNotifyCount,
} from "../../../../reducers/accountReducer.js";
import settings from "../../../../static/settings";
import firebaseApp from "../index.js";

const useNotifyCounter = () => {
  const fuser = useSelector((state) => state.account.firebaseUser);
  const notifyCount = useSelector((state) => state.account.notifyCount);
  const dispatch = useDispatch();
  let db = getFirestore(firebaseApp);

  useEffect(() => {
    if (fuser) {
      const doc_ref = doc(collection(db, "users"), fuser?.uid);
      const shards_ref = collection(doc_ref, "notify_shards");
      const q = query(shards_ref, where("count", ">=", 0));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let count = 0;
        querySnapshot.forEach((doc) => {
          count += doc.data().count || 0;
        });
        dispatch(setNotifyCount(count));
      });
      return () => unsub();
    }
  }, [fuser, db, dispatch]);

  return { notifyCount: notifyCount };
};

export const useInitNotifyCounter = () => {
  const db = getFirestore(firebaseApp);
  const batch = writeBatch(db);
  const num_shards = settings.firestore.num_shards;
  const fuser = useSelector((state) => state.account.firebaseUser);
  const dispatch = useDispatch();
  const notificationListItemsData = useSelector(
    (state) => state.account.notification.results
  );

  const init = useCallback(() => {
    if (fuser) {
      dispatch(initNotifyCount);
      const userRef = doc(collection(db, "users"), fuser.uid);
      batch.set(userRef, { num_shards: num_shards });

      for (let i = 0; i < num_shards; i++) {
        const shardRef = doc(
          collection(userRef, "notify_shards"),
          i.toString()
        );
        batch.set(shardRef, { count: 0 });
      }

      return batch.commit();
    }
  }, [fuser, dispatch, batch, db, num_shards]);

  useEffect(() => {
    init();
  }, [notificationListItemsData, init]);
};

export default useNotifyCounter;
