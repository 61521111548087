export const initialShopList = [
  // {
  //   shopId: 1,
  //   listId: 0,
  //   geom: {
  //     coordinates: [139.6413117, 35.7083222]
  //   },
  //   topImages: [{
  //     name: "イメージ",
  //     url: "https://source.unsplash.com/2cdvYh6ULCs/300x300",
  //     priorityId: 1
  //   }],
  //   name: 'init',
  //   message: 'init',
  // },
]

export const initialState = {
  shopList: [...initialShopList],
  newShopList: [...initialShopList],
  likeShopList: [...initialShopList],
  likeShopListCount: 0,
  selectedMarkerId: -1,
  gmapCenterLatLng: {
    lat: 35.7083225,
    lng: 139.6456891
  },
  gmapZoom: 12,
  isLoading: true,
  isNewShopListLoading: true,
  isLikeShopListLoading: true,
  gmapBounds: {
    nw: {
      lat: 0,
      lng: 0
    },
    se: {
      lat: 0,
      lng: 0
    },
    sw: {
      lat: 0,
      lng: 0
    },
    ne: {
      lat: 0,
      lng: 0
    }
  },
  page: 1
}

export default initialState