import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./config";

// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
//   if ("measurementId" in firebaseConfig) firebase.analytics();
// }
// firebase.auth().languageCode = "ja";

const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
