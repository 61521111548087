import { Avatar } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LinkWithState } from "../../../logics/utils/useRouterWithState";
import { setLogout } from "../../../reducers/accountReducer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    // flexGrow: 1,
    position: "absolute",
    top: "50%",
    left: "50%",
    height: "100%",
    transform: "translate(-50%, -50%)",
    webkitTransform: "translate(-50%, -50%)",
    msTransform: "translate(-50%, -50%)",
    align: "center",
    alignContent: "center",
  },
  titleLink: {
    textDecoration: "none",
    align: "center",
    color: theme.palette.text.primary,
  },
  avatar: {
    marginLeft: theme.spacing(2),
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

export default function MyAvatar(props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickLogout = () => {
    dispatch(setLogout());
    handleClose();
  };

  const classes = useStyles();
  const account = useSelector((state) => state.account.account);
  const accountPhoto =
    account &&
    account.userPublic &&
    account.userPublic.profilePhotoName &&
    account.userPublic.photoUrl;

  return (
    <React.Fragment>
      <Avatar
        onClick={handleClick}
        className={classes.avatar}
        src={accountPhoto}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
      >
        <MenuItem component={LinkWithState} onClick={handleClose} to="/mypage">
          マイページ
        </MenuItem>
        <MenuItem
          component={LinkWithState}
          onClick={handleClose}
          to="/postList/follow"
        >
          タイムライン
        </MenuItem>
        {/* <MenuItem component={Link} onClick={handleClose} to="/mypage/like">
          いいねした古着屋
        </MenuItem> */}
        <MenuItem
          component={LinkWithState}
          onClick={handleClose}
          to="/shopRegist"
        >
          店舗登録
        </MenuItem>
        <MenuItem onClick={handleClickLogout}>ログアウト</MenuItem>
      </Menu>
    </React.Fragment>
  );
}
