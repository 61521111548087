import Typography from '@material-ui/core/Typography';
import React from 'react';

export function NewLineTypography(props) {
  const { children, ...typographyProps } = props
  return (
    <Typography
      {...typographyProps}>
      {String(children).split("\n").map((t, i) => {
        return <React.Fragment key={i}>
          {t} <br />
        </React.Fragment>
      })}
    </Typography>
  )
}

