import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  DialogContent,
  DialogTitle,
} from "../../../components/molecules/dialogTemplete";
import LoadingButton from "../../../components/molecules/loadingButton";
import { verifyEmail } from "../../../logics/services/accounts";
import { useGoBackOrTop } from "../../../logics/utils/useRouterWithState";
import { setAccount } from "../../../reducers/accountReducer";
import SendVerifyMailError from "./errorDialog";
import SendVerifyMailSuccess from "./sendSuccess";
const useStyles = makeStyles((theme) => ({
  stepper: {
    padding: theme.spacing(0.5),
  },
  button: {
    margin: theme.spacing(1),
  },
  backButton: {
    margin: theme.spacing(1),
  },
  text: {
    margin: theme.spacing(2),
  },
}));

export default function ToVerifyEmailDialogMain(props) {
  const classes = useStyles();
  const { canClose, handleClose } = props;
  const [sendVerify, setSendVerify] = useState(false);
  const [sendVerifySuccess, setSendVerifySuccess] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const { goBackOrTop } = useGoBackOrTop();
  const dispatch = useDispatch();
  const handleSendVerify = async () => {
    setSendVerify(true);
    const resp = await verifyEmail();
    if (!resp.err) {
      setSendVerifySuccess(true);
      dispatch(setAccount());
    } else {
      setSendVerifySuccess(false);
      setErrorMsg(resp.message);
    }
  };
  const handleBack = canClose ? handleClose : goBackOrTop;
  return sendVerifySuccess === true ? (
    <SendVerifyMailSuccess handleClick={handleBack} />
  ) : sendVerifySuccess === false ? (
    <SendVerifyMailError handleClick={handleBack} error={errorMsg} />
  ) : (
    <Box>
      <DialogTitle id="customized-dialog-title">
        メールアドレスを認証してください
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6" className={classes.text}>
          {canClose
            ? "認証メールを再送信しますか?"
            : "引き続きこちらの機能を利用するにはお送りしたメールのリンクを開き、認証を完了してください。"}
        </Typography>
        <Box align="center" alignContent="center" my={3}>
          <Button
            variant="contained"
            onClick={handleBack}
            className={classes.backButton}
          >
            戻る
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleSendVerify}
            isLoading={sendVerify}
            className={classes.button}
            color="primary"
          >
            認証メールを再送信
          </LoadingButton>
        </Box>
      </DialogContent>
    </Box>
  );
}
