import { Backdrop } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

export default function CenterCircleLoading(props) {
    const { isLoading } = props

    return (
        <Backdrop open={isLoading} style={{ zIndex: 1500 }}>
            <CircularProgress color="primary" />
        </Backdrop>
    )
}
